import * as sha512 from 'js-sha512'
import { computed, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

import httpClient from '@/core/api/api.service'
import { FEED_ROUTE, LOGIN_ROUTE } from '@/constants/routes'
import {
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  SET_AUTH_USER,
  SET_PETS,
  SET_ACTIVE_PET,
  SET_AUTH_USER_STATUS,
} from '@/constants/types'

import useStorage from '@/composables/storage'
import usePets from '@/composables/pets'

import useNotifications from '@/composables/notifications'

const AUTH_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
}

export default function useAuth() {
  const router = useRouter()
  const store = useStore()
  const { getStorage, clearStorage } = useStorage()
  const { fetchNotifications } = useNotifications()
  const { stopWalking, getActiveWalking } = usePets()
  const user = reactive({
    email: '',
    password: '',
  })

  const AUTH_STATE = store.state.auth
  const PETS_STATE = computed(() => store.state.pet)
  const isAuthenticated = computed(() => store.getters.isAuthenticated)
  const isActiveAuthSession = computed(() => AUTH_STATE.status === AUTH_STATUS.ACTIVE)

  async function login(user, redirectRoute) {
    try {
      const { data } = await httpClient.save('login', {
        email: user.email,
        password: sha512(user.password),
      })

      await handleLoginSuccess(data.data, redirectRoute)

      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async function silentLogin(user) {
    try {
      const { data } = await httpClient.save('login', {
        email: user.email,
        password: user.password,
      })
      store.commit(SET_AUTH_USER_STATUS, AUTH_STATUS.INACTIVE)

      const { access_token, expires_at } = data.data
      store.commit(AUTH_SUCCESS, { access_token, expires_at })

      setAuthUser(user)

      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async function activateAuthSession() {
    try {
      store.commit(SET_AUTH_USER_STATUS, AUTH_STATUS.ACTIVE)
      const { data } = await httpClient.get('me')
      const user = data.data
      setAuthUser(user)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async function invalidateAuthSession() {
    await httpClient.save('logout')
    store.commit(AUTH_LOGOUT)
    clearStorage()
  }

  function setAuthUser(user) {
    store.commit(SET_AUTH_USER, user)

    store.commit(SET_PETS, user.pets)

    let activePetId = null
    if (user.active_pet_id) {
      activePetId = user.active_pet_id
    } else if (PETS_STATE.value.pets.length && !PETS_STATE.value.activePetId) {
      activePetId = user.pets[0].id
    }

    store.commit(SET_ACTIVE_PET, activePetId)
  }

  async function handleLoginSuccess({ access_token, expires_at }, redirectRoute) {
    store.commit(AUTH_SUCCESS, { access_token, expires_at })

    const { data } = await httpClient.get('me')
    const user = data.data
    setAuthUser(user)

    router.push({ name: redirectRoute ? redirectRoute : FEED_ROUTE })
    fetchNotifications()
  }

  async function logout() {
    try {
      await getActiveWalking()
      if (await getStorage('activeWalking')) {
        await stopWalking()
      }
      await httpClient.save('logout')
      store.commit(AUTH_LOGOUT)
      clearStorage()

      await router.replace({ name: LOGIN_ROUTE })

      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async function betaTesterSignUp() {
    try {
      await httpClient.save('beta-sign-in')

      const user = { ...AUTH_STATE.user, beta_tester: true }
      store.commit(SET_AUTH_USER, user)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async function savePushSettings(pushSettings) {
    try {
      await httpClient.update('me', 'push-settings', pushSettings)

      const user = { ...AUTH_STATE.user, push: pushSettings }
      store.commit(SET_AUTH_USER, user)

      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async function inviteUser(name, email) {
    try {
      await httpClient.save('users/invite', { name, email })

      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async function inviteDetails(token) {
    try {
      const data = await httpClient.get('users', `invite/${token}`)

      return Promise.resolve(data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  return {
    login,
    silentLogin,
    logout,
    user,
    AUTH_STATE,
    isAuthenticated,
    activateAuthSession,
    invalidateAuthSession,
    isActiveAuthSession,
    betaTesterSignUp,
    savePushSettings,
    inviteUser,
    inviteDetails,
  }
}
