<template>
  <div class="app-bottombar">
    <div
      class="app-bottombar__item"
      :class="{ 'router-link-active': isCurrentRoute(FEED_ROUTE) }"
      @click.prevent="goHome"
    >
      <ion-img :src="require('@/assets/images/icons/home.svg')" alt="Feed" class="app-bottombar__item__icon" />
    </div>
    <div
      class="app-bottombar__item"
      :class="{ 'router-link-active': isCurrentRoute(PET_SEARCH_ROUTE) }"
      @click.prevent="goSearch"
    >
      <ion-img :src="require('@/assets/images/icons/search.svg')" alt="Keresés" class="app-bottombar__item__icon" />
    </div>
    <div class="app-bottombar__item__navigation" @click.prevent="goDoxData">
      <svg :viewBox="`0 0 120 50`">
        <path :d="getPath()"></path>
      </svg>
      <div class="app-bottombar__item__navigation__dox">
        <lottie-animation
          ref="anim"
          :playInterval="6 * 1e4"
          :playOnClick="true"
          :animationData="require('@/assets/images/lottie/DOXmenu.json')"
        />
      </div>
    </div>
    <div class="app-bottombar__item" @click.prevent="goToPetFinder">
      <ion-img
        :src="
          isCurrentRoute(PET_FINDER_ROUTE)
            ? require('@/assets/images/radar-op.gif')
            : require('@/assets/images/icons/szimat.svg')
        "
        alt="Szimat"
        class="app-bottombar__item__icon"
      />
    </div>
    <div class="app-bottombar__item" @click.prevent="goToPetWalking">
      <ion-img
        :src="
          !isRunningIcon
            ? isCurrentRoute(PET_WALKING_ROUTE)
              ? require('@/assets/images/icons/Dox-walking-icon-active.svg')
              : require('@/assets/images/icons/seta.svg')
            : require('@/assets/images/animated/seta-anim.gif')
        "
        alt="Séta"
        class="app-bottombar__item__icon"
      />
    </div>
  </div>
</template>

<script>
import * as shape from 'd3-shape'
import { computed, defineComponent, ref } from 'vue'
import { alertController, IonImg, menuController } from '@ionic/vue'

import usePets from '@/composables/pets'
import useNavigation from '@/composables/navigation'
import usePosts from '@/composables/posts'
import usePetFinder from '@/composables/petFinder'

import { FEED_ROUTE, PET_SEARCH_ROUTE, PET_FINDER_ROUTE, DOX_DATA_ROUTE, PET_WALKING_ROUTE } from '@/constants/routes'

import router from '@/router'
import { Capacitor } from '@capacitor/core'

export default defineComponent({
  components: {
    IonImg,
  },
  setup() {
    const { activePet, PETS_STATE } = usePets()
    const { navigateTo } = useNavigation()
    const { goToFeed } = usePosts()
    const viewboxHeight = ref(50)

    const { isServiceRunning: petFindingIsRunning } = usePetFinder()

    const goHome = async () => {
      await menuController.close()
      goToFeed()
    }

    const goSearch = async () => {
      await menuController.close()
      await navigateTo({ name: PET_SEARCH_ROUTE })
    }

    const goDoxData = async () => {
      await menuController.close()
      await navigateTo({ name: DOX_DATA_ROUTE })
    }

    const goToPetFinder = async () => {
      if (Capacitor.isNative) {
        await menuController.close()
        await navigateTo({ name: PET_FINDER_ROUTE })
      } else {
        const dialog = await alertController.create({
          cssClass: 'confirm-dialog',
          header: 'A SZIMAT funkciót csak az appunkban tudod használni! Töltsd le most!',
          message: `<div class="ion-text-center">
                      <a href="https://play.google.com/store/apps/details?id=org.doxocial.application" target="_blank" class="os-badge">
                          <img src="${require('@/assets/images/os/google-play-badge-300.png')}" />
                      </a>
                      <a href="https://apps.apple.com/hu/app/doxocial/id1541389635" target="_blank" class="os-badge">
                          <img src="${require('@/assets/images/os/appstore-badge-300.png')}"/>
                      </a>
                      <a href="https://appgallery.huawei.com/#/app/C103535433" target="_blank" class="os-badge">
                          <img src="${require('@/assets/images/os/appgalery-badge-300.png')}"/>
                      </a>
                  </div>`,
          buttons: [
            {
              text: 'Mégse',
              role: 'cancel',
              cssClass: 'btn btn-link btn-block',
            },
          ],
        })
        return dialog.present()
      }
    }

    const goToPetWalking = async () => {
      await menuController.close()
      await navigateTo({ name: PET_WALKING_ROUTE })
    }

    const getPath = () => {
      const width = 120
      const height = 50

      const left = shape
        .line()
        .x(d => d.x)
        .y(d => d.y)([
        { x: 0, y: 0 },
        { x: width / 2, y: 0 },
        { x: width, y: 0 },
        { x: width, y: height },
        { x: 0, y: height },
        { x: 0, y: 0 },
      ])
      const tab = shape
        .line()
        .x(d => d.x)
        .y(d => d.y)
        .curve(shape.curveBasis)([
        { x: 0, y: 0 },
        { x: 20, y: 5 },
        { x: 30, y: height / 2 },

        { x: width / 3, y: height - height / 4 },
        { x: width / 2, y: height - 6 },
        { x: width - width / 3, y: height - height / 4 },

        { x: width - 30, y: height / 2 },
        { x: width - 20, y: 5 },
        { x: width, y: 0 },
      ])

      const bottom = shape
        .line()
        .x(d => d.x)
        .y(d => d.y)([
        { x: 0, y: height },
        { x: width, y: height },
        { x: width, y: height },
        { x: 0, y: height },
      ])
      return `${tab} ${left} ${bottom}`
    }

    const isCurrentRoute = route => route === router.currentRoute.value.name

    const isRunningIcon = computed(() => {
      return /*hasDox.value &&*/ PETS_STATE.activeWalking
    })

    return {
      goHome,
      goSearch,
      goDoxData,
      goToPetFinder,
      goToPetWalking,
      activePet,
      FEED_ROUTE,
      PET_SEARCH_ROUTE,
      PET_FINDER_ROUTE,
      PET_WALKING_ROUTE,
      isCurrentRoute,
      getPath,
      isRunningIcon,
      petFindingIsRunning,
      viewboxHeight,
    }
  },
})
</script>

<style lang="scss" scoped>
.app-bottombar {
  display: none;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  height: calc(50px + (constant(safe-area-inset-bottom) / 2));
  height: calc(50px + (env(safe-area-inset-bottom) / 2));
  z-index: 10;
  position: fixed;
  bottom: 0;
  text-align: center;

  @media only screen and (max-width: 1024px) {
    display: flex;
  }

  &__item {
    padding: 14px 20px 13px 20px;
    background: #fff;
    flex-shrink: 0;
    flex-grow: 1;
    transition: all 400ms cubic-bezier(0.23, 1, 0.32, 1);
    min-width: calc((100vw - 120px) / 4);

    @media only screen and (max-width: 360px) {
      padding: 15px 5px;
    }

    &.router-link-active {
      ion-img {
        // 3.8%
        filter: invert(25%) sepia(95%) saturate(3454%) hue-rotate(334deg) brightness(91%) contrast(98%);
      }
    }

    &__navigation {
      position: relative;
      max-width: 120px;
      min-width: 120px;
      height: 50px;

      &::after {
        content: '';
        position: absolute;
        width: 120px;
        background: #fff;
        bottom: calc(-1 * env(safe-area-inset-bottom));
        left: 0;
        height: constant(safe-area-inset-bottom);
        height: env(safe-area-inset-bottom);
      }

      & > svg {
        fill: #fff;
        height: 100%;
      }

      &__dox {
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
        display: flex;
        justify-content: center;
        border-radius: 50%;
        align-items: center;
        position: absolute;
        left: 50%;
        bottom: 14px;
        transform: translateX(-50%);
        width: 54px;
        height: 54px;
        background: #fff;

        .lottie-animation {
          height: 42px;
        }
      }
    }

    &__label {
      font-weight: var(--font-weight-bold);
      @media only screen and (max-width: 1024px) {
        display: none;
      }

      @media only screen and (min-width: 1025px) {
        text-transform: uppercase;
        font-family: var(--ion-poppins-font);
        font-size: 12px;
        letter-spacing: 0.03571em;
      }
    }

    &__icon {
      margin: 0 auto;
      max-width: 30px;
      max-height: 21px;
      height: 100%;
      width: 100%;
    }
  }
}
</style>
