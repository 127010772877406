<template>
  <ion-button class="notification-icon" @click="toggleMenu">
    <lottie-animation
      :playInterval="(hasNewNotification && getNewNotifications.length > 0) === true ? 20000 : null"
      :key="hasNewNotification && getNewNotifications.length"
      :playAuto="hasNewNotification && getNewNotifications.length"
      :animationData="require('@/assets/images/lottie/notifications.json')"
    />
    <!-- <ion-img :src="require('@/assets/images/icons/notification.svg')" alt="Notification icon" /> -->
    <ion-badge v-if="hasNewNotification && getNewNotifications.length" color="primary" class="notification-badge">
      {{ getNewNotifications.length }}
    </ion-badge>
  </ion-button>
</template>

<script>
import moment from 'moment'
import { defineComponent, computed, onMounted, onUnmounted } from 'vue'
import { IonImg, IonButton, IonBadge, menuController } from '@ionic/vue'

import useNavigation from '@/composables/navigation'
import useNotifications from '@/composables/notifications'

export default defineComponent({
  name: 'NotificationIcon',
  components: {
    IonImg,
    IonButton,
    IonBadge,
  },
  setup() {
    const { navigateTo } = useNavigation()
    const { NOTIFICATION_STATE, getNewNotifications, fetchNotifications, setAllNotificationsRead } = useNotifications()

    const notifications = computed(() => NOTIFICATION_STATE.notifications)
    const notificationsReadDate = computed(() => NOTIFICATION_STATE.readAt)

    const hasNewNotification = computed(() => {
      const INITIAL_DATE = moment('1970.01.01').format()

      return (
        notifications.value &&
        notifications.value.length &&
        notifications.value.find(notification => {
          return moment(notification.date).isAfter(notificationsReadDate.value || INITIAL_DATE)
        })
      )
    })

    async function toggleMenu() {
      const isOpen = await menuController.isOpen()

      if (!isOpen) {
        fetchNotifications()
        setAllNotificationsRead()
      }

      menuController.enable('notification-menu')
      menuController.toggle('notification-menu')
    }

    let notificationRefreshInterval

    onMounted(() => {
      const NOTIFICATION_FETCH_INTERVAL = 120000

      notificationRefreshInterval = setInterval(fetchNotifications, NOTIFICATION_FETCH_INTERVAL)
    })

    onUnmounted(() => {
      clearInterval(notificationRefreshInterval)
    })

    return {
      navigateTo,
      getNewNotifications,
      notifications,
      hasNewNotification,
      toggleMenu,
    }
  },
})
</script>

<style lang="scss" scoped>
.notification-icon {
  position: relative;
  right: -12px;
  top: 3px;

  .lottie-animation {
    min-width: 31px;
    max-width: 31px;
    position: relative;
    left: -3px;
  }
}
.notification-badge {
  position: absolute;
  top: 0;
  right: 2px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  padding: 0;
  background-color: #e6224a;
}
</style>
