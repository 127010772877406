import { USER_PHOTO_ROUTE, USER_PHOTO_PATH } from '@/constants/routes'

export default [
  {
    name: USER_PHOTO_ROUTE,
    path: USER_PHOTO_PATH,
    component: () => import(/* webpackChunkName: "user" */ '@/components/User/Ownerphoto.vue'),
    meta: {
      authenticatedOnly: true,
      canGoBack: true,
    },
  },
]
