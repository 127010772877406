import { TOGGLE_PET_MENU } from '@/constants/types'
import { isBoolean } from 'lodash'

const state = {
  petMenuOpened: false,
}

const getters = {
  isPetMenuOpened: state => state.petMenuOpened,
}

const actions = {}

const mutations = {
  [TOGGLE_PET_MENU](state, toggle) {
    state.petMenuOpened = isBoolean(toggle) ? toggle : !state.petMenuOpened
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
