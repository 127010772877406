<template>
  <ion-avatar @click.prevent="goToChat" class="notification-img">
    <ion-img :src="notification.pet.avatar" :alt="notification.pet.name"></ion-img>
  </ion-avatar>
  <div class="notification-content" @click.prevent="goToChat">
    <ion-text class="notification-title">
      <strong>{{ notification.pet.name }}</strong> üzenetet küldött
      <strong>{{ notification.target_pet.name }}</strong> számára.
    </ion-text>
    <time class="notification-date">{{ getNotificationDate(notification) }}</time>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { IonAvatar, IonImg, IonText, menuController } from '@ionic/vue'

import useNotifications from '@/composables/notifications'
import useNavigation from '@/composables/navigation'

import { PAGE_CHAT_ROUTE } from '@/constants/routes'

export default defineComponent({
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  components: {
    IonAvatar,
    IonImg,
    IonText,
  },
  setup(props) {
    const { getNotificationDate } = useNotifications()
    const { navigateTo } = useNavigation()

    async function goToChat() {
      await menuController.close()
      await navigateTo({
        name: PAGE_CHAT_ROUTE,
        params: { pet: props.notification.pet.id, mypet: props.notification.target_pet.id },
      })
    }

    return {
      getNotificationDate,
      goToChat,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '../../theme/components/notifications';
</style>
