<template>
  <ion-page>
    <ion-content class="page-center" fullscreen>
      <div class="container">
        <div class="content">
          <ion-img :src="require('@/assets/images/404.svg')" alt="404"></ion-img>
          <ion-button @click="navigateTo({ name: FEED_ROUTE })" expand="full" shape="round" strong="true" class="btn">
            Vissza a főoldalra
            <ion-ripple-effect></ion-ripple-effect>
          </ion-button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from 'vue'
import { IonPage, IonContent, IonImg, IonButton, IonRippleEffect } from '@ionic/vue'

import { FEED_ROUTE } from '@/constants/routes'
import useNavigation from '@/composables/navigation'

export default defineComponent({
  name: 'PageNotFound',
  components: {
    IonPage,
    IonContent,
    IonImg,
    IonButton,
    IonRippleEffect,
  },
  setup() {
    const { navigateTo } = useNavigation()

    return {
      navigateTo,
      FEED_ROUTE,
    }
  },
})
</script>

<style lang="scss" scoped>
.ion-page {
  opacity: 1 !important;
}

ion-button {
  --background: #e6224a;
  --box-shadow: none;
}

.content {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

ion-img {
  width: 100%;
  margin-bottom: 40px;
}
</style>
