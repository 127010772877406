import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { IonicVue } from '@ionic/vue'
import { defineCustomElements } from '@ionic/pwa-elements/loader'
import { DoubleClick } from '@/directives/double-click'
import { DisableSwipeBackDirective } from 'v-disable-swipe-back'
import Lottie from '@/components/Animations/Lottie'
import { GesturePlugin } from '@vueuse/gesture'
import { MotionPlugin } from '@vueuse/motion'
import VueScrollPicker from 'vue-scroll-picker'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'

/* Theme variables */
import './theme/variables.css'

import { setupCalendar } from 'v-calendar'

const app = createApp(App).use(IonicVue).use(router).use(store)

app.use(GesturePlugin)
app.use(MotionPlugin)
app.use(VueScrollPicker)

app.use(setupCalendar, {})

app.component('lottie-animation', Lottie)

app.directive('doubleClick', DoubleClick)
app.directive('disable-swipe-back', DisableSwipeBackDirective)

router.isReady().then(() => {
  app.mount('#app')
  defineCustomElements(window)
})
