<template>
  <ion-menu class="menu" side="start" content-id="main" :swipe-gesture="hasSwipe">
    <ion-content class="ion-text-center">
      <ion-list class="menu-list ion-no-padding">
        <ion-item class="menu-item link" lines="none" @click.prevent="login"> Bejelentkezés </ion-item>
        <ion-item class="menu-item link" lines="none" @click.prevent="register"> Regisztráció </ion-item>
        <ion-item class="menu-item link" lines="none" @click.prevent="goToAbout"> Névjegy </ion-item>
      </ion-list>
    </ion-content>
  </ion-menu>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { IonMenu, IonContent, IonList, IonItem, isPlatform, menuController } from '@ionic/vue'

import { LOGIN_ROUTE, PAGE_ABOUT_ROUTE, REGISTRATION_ROUTE } from '@/constants/routes'
import useNavigation from '@/composables/navigation'

export default defineComponent({
  name: 'GuestMenu',
  components: {
    IonMenu,
    IonContent,
    IonList,
    IonItem,
  },
  setup() {
    const { navigateTo } = useNavigation()

    const hasSwipe = computed(() => !isPlatform('desktop'))

    const login = async () => {
      await menuController.close()
      navigateTo({ name: LOGIN_ROUTE })
    }

    const register = async () => {
      await menuController.close()
      navigateTo({ name: REGISTRATION_ROUTE })
    }

    const goToAbout = async () => {
      await menuController.close()
      navigateTo({ name: PAGE_ABOUT_ROUTE })
    }

    return {
      hasSwipe,
      login,
      register,
      goToAbout,
    }
  },
})
</script>

<style lang="scss" scoped>
ion-menu {
  @media (max-width: 767px) {
    --min-width: 100%;
    --max-width: 100%;
    top: 56px;
  }

  @media (min-width: 768px) {
    top: 65px;
  }

  @media (min-width: 768px) {
    --min-width: clamp(300px, calc((100vw - 640px) / 2), 400px);
    --max-width: clamp(300px, calc((100vw - 640px) / 2), 400px);
  }
}

ion-content {
  --padding-start: 20px;
  --padding-end: 20px;
}

.menu {
  color: var(--ion-color-dark);

  .btn-dox {
    width: 100%;
    font-size: 0.75rem;
  }

  .btn-logout {
    display: inline-block;
    margin: 0 calc(-1 * var(--padding-start)) 0 calc(-1 * var(--padding-end));
    text-transform: initial;
    float: left;
  }

  .menu-item {
    --inner-padding-top: 15px;
    --inner-padding-bottom: 15px;
    --padding-start: 0;
    --inner-padding-end: 0;
    --border-color: #e5e5e5;
    font-size: 14px;
    font-weight: bold;

    &--pet-list {
      --inner-padding-bottom: 30px;
      box-shadow: 0 2px 20px 0 rgb(0 0 0 / 20%);
    }

    &--buy-dox {
      --inner-padding-top: 4px;
    }

    &--last-before {
      --inner-padding-bottom: 20px;
    }

    &--logout {
      --inner-padding-top: 9px;
    }

    &.link {
      cursor: pointer;
    }
  }
}
</style>
