import { ref } from 'vue'

export const DoubleClick = {
  beforeMount(element, binding) {
    const waitForDoubleTap = ref(false)

    element.addEventListener('click', event => {
      if (!waitForDoubleTap.value) {
        waitForDoubleTap.value = setTimeout(() => {
          clearTimeout(waitForDoubleTap.value)
          waitForDoubleTap.value = null
        }, 300)

        return
      }

      clearTimeout(waitForDoubleTap.value)
      waitForDoubleTap.value = null

      binding.value(event)
    })
  },
}
