export const FEED_ROUTE = 'feed'
export const FEED_PATH = '/feed'

export const POST_ROUTE = 'post'
export const POST_PATH = '/posts/:id'
export const POST_CREATE_ROUTE = 'create-post'
export const POST_CREATE_PATH = '/posts/create'
export const POST_EDIT_ROUTE = 'edit-post'
export const POST_EDIT_PATH = '/posts/:id/edit'

export const LOGIN_ROUTE = 'login'
export const LOGIN_PATH = '/login'

export const PASSWORD_RESET_ROUTE = 'password-reset'
export const PASSWORD_RESET_PATH = '/password-reset'
export const PASSWORD_RESET_NEW_ROUTE = 'password-reset-new'
export const PASSWORD_RESET_NEW_PATH = '/password-reset/:hash'

export const USER_PHOTO_ROUTE = 'user-photo'
export const USER_PHOTO_PATH = '/user-photo'

export const PETS_ROUTE = 'pets'
export const PETS_PATH = '/pets'
export const PET_ROUTE = 'pet'
export const PET_PATH = '/pets/:id/:refresh?'
export const PET_LOST_ROUTE = 'pet-lost'
export const PET_LOST_PATH = '/pets/:id/lost'
export const PET_MESSAGE_ROUTE = 'message-pet'
export const PET_MESSAGE_PATH = '/pets/:id/message'
export const PET_INFO_ROUTE = 'pet-info'
export const PET_INFO_PATH = '/pets/:id/info'
export const PET_EDIT_ROUTE = 'pet-edit'
export const PET_EDIT_PATH = '/pets/:id/edit'
export const PET_SEARCH_ROUTE = 'pet-search'
export const PET_SEARCH_PATH = '/search'

export const REGISTRATION_ROUTE = 'registration'
export const REGISTRATION_PATH = '/registration'
export const REGISTRATION_WELCOME_ROUTE = 'registration-welcome'
export const REGISTRATION_WELCOME_PATH = '/registration/welcome'
export const REGISTRATION_SUCCESS_ROUTE = 'registration-success'
export const REGISTRATION_SUCCESS_PATH = '/registration/success'
export const REGISTRATION_ACTIVATION_ROUTE = 'registration-activation'
export const REGISTRATION_ACTIVATION_PATH = '/registration-activation/:hash'

export const DOX_READER_ROUTE = 'dox-reader'
export const DOX_READER_PATH = '/woof/:hash'
export const DOX_PAIR_ROUTE = 'dox-pair'
export const DOX_PAIR_PATH = '/registration/dox-pair'
export const DOX_DATA_ROUTE = 'dox-data'
export const DOX_DATA_PATH = '/dox-data'
export const DOX_READ_MAP_ROUTE = 'dox-data-read-map'
export const DOX_READ_MAP_PATH = '/dox-data-read-map/:lat/:long'

export const PAGE_ABOUT_ROUTE = 'page-about'
export const PAGE_ABOUT_PATH = '/about'

export const PET_FINDER_ROUTE = 'pet-finder'
export const PET_FINDER_PATH = '/pet-finder'
export const SNIFF_SETTINGS_ROUTE = 'pet-finder-settings'
export const SNIFF_SETTINGS_PATH = '/pet-finder-settings'

export const PET_WALKING_ROUTE = 'pet-walking'
export const PET_WALKING_PATH = '/pet-walking'

export const PAGE_SETTINGS_ROUTE = 'page-settings'
export const PAGE_SETTINGS_PATH = '/settings'

export const PAGE_PRESENT_FOUND_ROUTE = 'page-present-found'
export const PAGE_PRESENT_FOUND_PATH = '/poi/present/:id'

export const PAGE_PRESENT_SCAN_ROUTE = 'page-present-scan'
export const PAGE_PRESENT_SCAN_PATH = '/poi/present/:id/scan/:code'

export const PAGE_PRESENT_CAMERA_ROUTE = 'page-present-camera'
export const PAGE_PRESENT_CAMERA_PATH = '/poi/present/:id/camera'

export const PAGE_WALK_LEADERBOARD_ROUTE = 'page-walk-leaderboard'
export const PAGE_WALK_LEADERBOARD_PATH = '/walk-leaderboard'

export const PAGE_MESSAGES_ROUTE = 'page-messages'
export const PAGE_MESSAGES_PATH = '/messages'

export const PAGE_CHAT_ROUTE = 'page-chat'
export const PAGE_CHAT_PATH = '/chat/:pet/:mypet'

export const PAGE_POI_RATE_ROUTE = 'page-poi-rate'
export const PAGE_POI_RATE_PATH = '/poi/rate/:id'

export const PAGE_POI_CREATE_ROUTE = 'page-poi-create'
export const PAGE_POI_CREATE_PATH = '/poi/create/:type/:lat/:lng'

export const PAGE_EVENT_CREATE_ROUTE = 'page-event-create'
export const PAGE_EVENT_CREATE_PATH = '/event/create/:lat/:lng'

export const PAGE_EVENT_CHAT_ROUTE = 'page-event-chat'
export const PAGE_EVENT_CHAT_PATH = '/event/:id/chat'

export const PAGE_EVENT_EDIT_ROUTE = 'page-event-edit'
export const PAGE_EVENT_EDIT_PATH = '/event/edit/:id'

export const PAGE_POI_EDIT_ROUTE = 'page-poi-edit'
export const PAGE_POI_EDIT_PATH = '/poi/edit/:id'

export const USER_INVITE_ROUTE = 'user-invite'
export const USER_INVITE_PATH = '/user/invite'

export const INVITE_ACCEPT_ROUTE = 'invite-accept'
export const INVITE_ACCEPT_PATH = '/invite/accept/:token'

export const EVENT_CALENDAR_ROUTE = 'page-event-calendar'
export const EVENT_CALENDAR_PATH = '/event/calendar'

export const POI_REDIRECT_ROUTE = 'page-poi-redirect'
export const POI_REDIRECT_PATH = '/poi/:slug'

export const EVENT_REDIRECT_ROUTE = 'page-event-redirect'
export const EVENT_REDIRECT_PATH = '/event/:slug'

export const AI_ROUTE = 'page-ai'
export const AI_PATH = '/ai'
