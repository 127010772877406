<template>
  <ion-list class="pet-list">
    <div v-for="pet in pets" :key="pet.id" class="pet-list-item" lines="none">
      <div @click="setPetActive(pet)" class="pet-item">
        <ion-avatar class="pet-avatar">
          <ion-img :src="pet.avatar" :alt="pet.name" class="avatar" />
          <div class="pet-status pet-active" v-if="isPetActive(pet)">
            <ion-img :src="require('@/assets/images/icons/check.svg')" />
          </div>
          <div class="pet-status pet-missing" v-if="isPetMissing(pet)">
            <ion-img :src="require('@/assets/images/icons/exclamation.svg')" />
          </div>
        </ion-avatar>
        <ion-label class="pet-name">{{ pet.name }}</ion-label>
      </div>
    </div>
    <div class="pet-list-item add-new-pet" lines="none">
      <div @click="addNewPet()" class="pet-item">
        <ion-avatar class="pet-avatar">
          <ion-img :src="require('@/assets/images/icons/menu/pet.jpg')" alt="add-new-pet" class="avatar" />
        </ion-avatar>

        <ion-label class="pet-name">Új Kedvenc hozzáadása</ion-label>
      </div>
    </div>
    <div class="pet-list-item add-new-pet" lines="none">
      <div @click="addNewFamilyMember()" class="pet-item">
        <ion-avatar class="pet-avatar">
          <ion-img :src="require('@/assets/images/icons/menu/family.jpg')" alt="add-new-pet" class="avatar" />
        </ion-avatar>

        <ion-label class="pet-name">Új Családtag meghívása</ion-label>
      </div>
    </div>
  </ion-list>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { IonImg, IonList, IonAvatar, IonLabel, menuController } from '@ionic/vue'

import useNavigation from '@/composables/navigation'
import { PET_ROUTE, REGISTRATION_ROUTE, USER_INVITE_ROUTE } from '@/constants/routes'
import PET_STATUS from '@/constants/pet-status'

import usePets from '@/composables/pets'
import useNotifications from '@/composables/notifications'
import usePosts from '@/composables/posts'

export default defineComponent({
  name: 'PetList',
  components: {
    IonImg,
    IonList,
    IonAvatar,
    IonLabel,
  },
  setup() {
    const { setActivePet, PETS_STATE, petsWithActivePetFirst } = usePets()
    const { navigateTo } = useNavigation()
    const { fetchNotifications } = useNotifications()
    const { fetchPosts, resetFeed } = usePosts()

    const pets = petsWithActivePetFirst
    const activePetId = computed(() => PETS_STATE.activePetId)

    function isPetActive(pet) {
      return pet.id === activePetId.value
    }

    function isPetMissing(pet) {
      return pet.status === PET_STATUS.MISSING.name
    }

    async function setPetActive(pet) {
      console.log('set active pet petlist', pet)
      await setActivePet(pet)
      await menuController.close()
      navigateTo({ name: PET_ROUTE, params: { id: pet.id } })
      resetFeed()
      fetchPosts()
      fetchNotifications()
    }

    async function addNewPet() {
      await menuController.close()
      navigateTo({ name: REGISTRATION_ROUTE })
    }

    const addNewFamilyMember = async () => {
      await menuController.close()
      navigateTo({ name: USER_INVITE_ROUTE })
    }

    return {
      pets,
      isPetActive,
      setPetActive,
      isPetMissing,
      addNewPet,
      addNewFamilyMember,
    }
  },
})
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap');

.pet-list {
  display: flex;
  overflow-x: auto;
  min-width: min(600px, 100vw);
}

.pet-list-item {
  max-width: 100px;

  &.add-new-pet {
    color: #d9d9d9;

    ion-label {
      font-size: 10px;
    }
  }

  &:first-of-type .pet-item {
    padding-left: 20px;
  }

  &:last-of-type .pet-item {
    padding-right: 20px;
  }
}

.pet-item {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 20px 10px;
}

.pet-avatar {
  position: relative;
  height: auto;
  width: auto;
  margin: 0;
  border-radius: 50%;
  filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.2));

  .avatar {
    min-width: 67px;
    min-height: 67px;
    width: 67px;
    height: 67px;
    border-radius: 50%;
    position: relative;
    margin: 0 auto;
    border: 6px solid #fff;
  }

  .pet-status {
    position: absolute;
    bottom: 3px;
    right: 3px;

    max-width: 15px;
    max-height: 15px;
    width: 15px;
    height: 15px;

    display: flex;
    align-items: center;
    justify-content: center;

    &.pet-active {
      background: #4ce552;
      border-radius: 50%;

      ion-img {
        width: 9px;
      }
    }

    &.pet-missing {
      background: #f64242;
      border-radius: 50%;

      ion-img {
        height: 9px;
      }
    }
  }
}

.pet-name {
  font-size: 13px;
  font-weight: 900;
  text-align: center;
  margin-top: 11px;
  white-space: normal;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: -0.3px;
}
</style>
