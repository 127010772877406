<template>
  <template v-if="isAuthenticated && !isViewFullscreen && hasPets">
    <AppHeader />
    <MainMenu />
    <FloatingMenu />
    <NotificationsMenu />
    <AppBottomBar />
  </template>

  <template v-if="!isAuthenticated && !isViewFullscreen">
    <GuestHeader />
    <GuestMenu />
  </template>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { useRoute } from 'vue-router'

import useAuth from '@/composables/auth'
import usePets from '@/composables/pets'

import AppHeader from '@/components/Navigation/AppHeader'
import GuestHeader from '@/components/Navigation/GuestHeader'
import MainMenu from '@/components/Navigation/MainMenu'
import GuestMenu from '@/components/Navigation/GuestMenu'
import FloatingMenu from '@/components/Navigation/FloatingMenu'
import NotificationsMenu from '@/components/Navigation/NotificationsMenu'
import AppBottomBar from '@/components/Navigation/AppBottomBar'

export default defineComponent({
  name: 'AppNavigation',
  components: {
    AppHeader,
    GuestHeader,
    MainMenu,
    GuestMenu,
    FloatingMenu,
    NotificationsMenu,
    AppBottomBar,
  },
  setup() {
    const route = useRoute()
    const { isAuthenticated } = useAuth()
    const { PETS_STATE } = usePets()

    const hasPets = computed(() => PETS_STATE.pets && PETS_STATE.pets.length)
    const isViewFullscreen = computed(() => route.meta.fullscreen)

    return {
      isAuthenticated,
      isViewFullscreen,
      hasPets,
    }
  },
})
</script>
