<template>
  <div ref="animation" class="lottie-animation" @click="playOnClick" />
</template>

<script>
import lottie from 'lottie-web'
import { defineExpose, ref } from 'vue'

export default {
  props: {
    animationData: Object,
    loop: Boolean,
    playAuto: {
      default: true,
      type: Boolean,
    },
    autoPlay: Boolean,
    speed: Number,
    playOnClick: Boolean,
    playOnMount: Boolean,
    playInterval: Number,
    onClick: Event,
  },
  setup(props) {
    const anim = ref(null)
    const playAnim = () => {
      anim.value?.goToAndPlay(0)
    }

    const stop = () => {
      anim.value?.stop()
    }

    const playOnClick = () => {
      if (props.onClick) props.onClick()

      if (!props.playOnClick) return
      anim.value?.goToAndPlay(0)
    }

    defineExpose({ anim })

    return {
      anim,
      stop,
      playAnim,
      playOnClick,
    }
  },
  mounted() {
    if (this.$refs.animation) {
      this.anim = lottie.loadAnimation({
        container: this.$refs.animation,
        renderer: 'svg',
        loop: this.loop ?? false,
        autoplay: this.playAuto,
        animationData: this.animationData,
      })

      this.anim.setSpeed(this.speed ?? 1)

      if (this.playOnMount) {
        this.anim.goToAndPlay(0)
      }

      if (this.playInterval) {
        setInterval(() => {
          this.anim.goToAndPlay(0)
        }, this.playInterval)
      }
    }
  },
}
</script>
