import _ from 'lodash'
import moment from 'moment'
import {
  SET_NOTIFICATIONS,
  SET_NOTIFICATION_READ,
  SET_ALL_NOTIFICATIONS_READ,
  SET_NOTIFICATIONS_READ_AT,
} from '@/constants/types'

import useStorage from '@/composables/storage'

const { setStorage } = useStorage()

const state = {
  notifications: [],
  readAt: null,
}

const INITIAL_DATE = moment('1970.01.01').format()

const getters = {
  getNewNotifications: state =>
    state.notifications &&
    state.notifications.length &&
    state.notifications.filter(notification => moment(notification.date).isAfter(state.readAt || INITIAL_DATE)),
  getReadNotifications: state =>
    state.notifications.filter(
      notification => notification.readAt && moment(notification.readAt).isAfter(notification.date),
    ),
}

const actions = {}

const mutations = {
  [SET_NOTIFICATIONS](state, data) {
    state.notifications = data
    setStorage('notifications', data)
  },
  [SET_NOTIFICATION_READ](state, notification) {
    _.find(state.notifications, { id: notification.id }).readAt = moment().format()
  },
  [SET_ALL_NOTIFICATIONS_READ](state) {
    state.notifications = [...state.notifications].map(notification => ({ ...notification, readAt: moment().format() }))
  },
  [SET_NOTIFICATIONS_READ_AT](state, date) {
    state.readAt = date
    setStorage('notificationsReadDate', date)
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
