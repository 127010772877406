import moment from 'moment'

import httpClient from '@/core/api/api.service'
import AuthService from '@/core/auth/services/auth.service'
import { AUTH_LOGOUT, AUTH_SUCCESS, SET_AUTH_USER, SET_AUTH_USER_STATUS, SET_AUTH_TOKEN } from '@/constants/types'

const state = {
  user: null,
  authToken: '',
  authTokenExpiry: '',
  status: 'active',
}

const getters = {
  isAuthenticated: state => !!state.authToken && moment().isBefore(state.authTokenExpiry),
}

const actions = {}

const mutations = {
  [AUTH_SUCCESS](state, { access_token, expires_at }) {
    AuthService.setAuthToken(access_token)
    AuthService.setAuthTokenExpiry(expires_at)
    httpClient.setAuthorizationHeader(access_token)
    state.authToken = access_token
    state.authTokenExpiry = expires_at
  },
  [AUTH_LOGOUT](state) {
    AuthService.removeAuthToken()
    AuthService.removeAuthTokenExpiry()
    AuthService.removeAuthUser()
    state.authToken = ''
    state.authTokenExpiry = ''
    state.user = null
  },
  [SET_AUTH_USER](state, data) {
    AuthService.setAuthUser(data)
    state.user = data
  },
  [SET_AUTH_TOKEN](state, { authToken, authTokenExpiry }) {
    state.authToken = authToken
    state.authTokenExpiry = authTokenExpiry
  },
  [SET_AUTH_USER_STATUS](state, status) {
    state.status = status
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
