import {
  LOGIN_ROUTE,
  LOGIN_PATH,
  PASSWORD_RESET_NEW_ROUTE,
  PASSWORD_RESET_NEW_PATH,
  PASSWORD_RESET_ROUTE,
  PASSWORD_RESET_PATH,
} from '@/constants/routes'

export default [
  {
    path: '/',
    name: LOGIN_ROUTE,
    alias: LOGIN_PATH,
    component: () => import(/* webpackChunkName: "auth" */ '@/views/Login.vue'),
    meta: {
      unauthenticatedOnly: true,
      fullscreen: true,
    },
  },
  {
    path: PASSWORD_RESET_PATH,
    name: PASSWORD_RESET_ROUTE,
    component: () => import(/* webpackChunkName: "auth" */ '@/views/PasswordReset/Index.vue'),
    meta: {
      unauthenticatedOnly: true,
      fullscreen: true,
    },
  },
  {
    path: PASSWORD_RESET_NEW_PATH,
    name: PASSWORD_RESET_NEW_ROUTE,
    component: () => import(/* webpackChunkName: "auth" */ '@/views/PasswordReset/PasswordResetNew.vue'),
    meta: {
      fullscreen: true,
    },
  },
]
