<template>
  <ion-avatar class="pet-avatar-icon" :class="`pet-avatar-icon--${color}`">
    <ion-img :src="avatar" :alt="name" />
  </ion-avatar>
  <ion-text class="pet-avatar-icon-name">{{ name }}</ion-text>
</template>

<script>
import { defineComponent } from 'vue'
import { IonImg, IonAvatar, IonText } from '@ionic/vue'

export default defineComponent({
  name: 'PetAvatarIcon',
  components: {
    IonImg,
    IonAvatar,
    IonText,
  },
  props: {
    avatar: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: 'green',
    },
  },
})
</script>

<style lang="scss" scoped>
.pet-avatar-icon {
  border: 2px solid grey;
  padding: 2px;

  @media (max-width: 767px) {
    width: 38px;
    min-width: 38px;
    height: 38px;
  }

  @media (min-width: 768px) {
    width: 44px;
    min-width: 44px;
    height: 44px;
  }

  &--red {
    border-color: #f64242;
  }

  &--green {
    border-color: #a3ffc8;
  }

  &-name {
    @media (max-width: 767px) {
      display: none;
    }

    @media (min-width: 76px) {
      margin-left: 10px;
      font-size: 1rem;
      font-weight: 700;
    }
  }
}
</style>
