<template>
  <ion-avatar @click.prevent.stop="goToProfile" class="notification-img">
    <ion-img :src="targetPet.avatar" :alt="targetPet.name"></ion-img>
  </ion-avatar>
  <div class="notification-content">
    <ion-text class="notification-title">
      Közeledik
      <strong>{{ targetPet.name }}</strong> kötelező oltása!
    </ion-text>
    <p class="notification-sub">Ellenőrizd a következő oltás időpontját!</p>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue'
import { IonAvatar, IonImg, IonText, menuController } from '@ionic/vue'

import usePets from '@/composables/pets'
import useNavigation from '@/composables/navigation'

export default defineComponent({
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  components: {
    IonAvatar,
    IonImg,
    IonText,
  },
  setup(props) {
    const { activePet, PETS_STATE } = usePets()
    const { navigateTo } = useNavigation()

    const targetPet = computed(
      () => PETS_STATE.pets.find(pet => pet.id === props.notification.target_pet.id) ?? activePet,
    )

    const goToProfile = async () => {
      await menuController.close()
      navigateTo({ name: 'pet-edit', params: { id: props.notification.target_pet.id } })
    }

    return {
      activePet,
      targetPet,
      goToProfile,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '../../theme/components/notifications';
</style>
