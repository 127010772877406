<template>
  <ion-header class="app-header">
    <ion-toolbar class="container">
      <ion-buttons slot="start" class="ion-justify-content-start">
        <ion-back-button
          v-show="canGoBack && hasHistory"
          :defaultHref="backRoute ?? FEED_PATH"
          mode="md"
          :icon="require('@/assets/images/icons/back.svg')"
        ></ion-back-button>
        <div
          v-show="activePet && (!canGoBack || !hasHistory)"
          auto-hide="false"
          class="menu-button"
          @click="togglePetMenu()"
        >
          <PetAvatarIcon :avatar="activePet.avatar" :name="activePet.name" :color="activePetColor" />
        </div>
      </ion-buttons>
      <ion-buttons slot="primary" class="ion-justify-content-end">
        <NotificationIcon />
      </ion-buttons>

      <ion-title>
        <ion-img :src="require('@/assets/images/logo.svg')" alt="logo" @click="goToFeed()" class="logo" />
      </ion-title>
    </ion-toolbar>
  </ion-header>
</template>

<script>
import { defineComponent, computed, watch, ref } from 'vue'
import { IonHeader, IonTitle, IonToolbar, IonImg, IonButtons, IonBackButton } from '@ionic/vue'
import { useRoute } from 'vue-router'

import useApp from '@/composables/app'
import useNavigation from '@/composables/navigation'
import usePosts from '@/composables/posts'
import { FEED_ROUTE, FEED_PATH } from '@/constants/routes'
import usePets from '@/composables/pets'
import PET_STATUS from '@/constants/pet-status'

import PetAvatarIcon from '@/components/Pet/PetAvatarIcon.vue'
import NotificationIcon from '@/components/Navigation/NotificationIcon.vue'

export default defineComponent({
  name: 'AppHeader',
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonImg,
    IonButtons,
    IonBackButton,
    PetAvatarIcon,
    NotificationIcon,
  },
  setup() {
    const route = useRoute()
    const { togglePetMenu } = useApp()
    const { navigateTo } = useNavigation()
    const { activePet } = usePets()
    const { goToFeed } = usePosts()

    const hasHistory = ref(false)
    const backRoute = computed(() => route.meta.goBackRoute)
    const canGoBack = computed(() => route.meta.canGoBack)
    const activePetColor = computed(() => (activePet.value.status === PET_STATUS.MISSING.name ? 'red' : 'green'))

    watch(
      () => route.name,
      () => {
        hasHistory.value = true
      },
    )

    return {
      navigateTo,
      canGoBack,
      FEED_ROUTE,
      FEED_PATH,
      activePet,
      activePetColor,
      goToFeed,
      hasHistory,
      togglePetMenu,
      backRoute,
    }
  },
})
</script>

<style lang="scss" scoped>
.app-header {
  background: var(--ion-toolbar-background, var(--ion-background-color, #fff));
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);

  &::after {
    content: none;
    display: none;
  }
}

ion-buttons {
  @media (max-width: 767px) {
    width: 40px;
  }

  @media (min-width: 768px) {
    width: 20%;
  }
}

ion-title {
  @media (min-width: 768px) {
    //width: 60%;
    margin: 0 auto;
  }
}

.container {
  display: flex;
  height: var(--header-height-web);
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);

  @media only screen and (max-width: 600px) {
    height: var(--header-height-mobile);
    &.ios {
      --header-height-mobile: 115px;
    }
  }
  /*@media (max-width: 767px) {
    height: 56px;

    &.ios {
      height: 76px;

      @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
        // iPhone 12 Mini, iPhone 11 Pro, iPhone Xs, and iPhone X
        height: 105px;
        padding-top: constant(safe-area-inset-top);
        padding-top: env(safe-area-inset-top);
      }

      @media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
        // iPhone 12 and iPhone 12 Pro
        height: 105px;
        padding-top: constant(safe-area-inset-top);
        padding-top: env(safe-area-inset-top);
      }

      @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
        // iPhone 11 and iPhone XR
        height: 105px;
        padding-top: constant(safe-area-inset-top);
        padding-top: env(safe-area-inset-top);
      }

      @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
        // iPhone 11 Pro Max and iPhone Xs Max
        height: 105px;
        padding-top: constant(safe-area-inset-top);
        padding-top: env(safe-area-inset-top);
      }
    }
  }

  @media (min-width: 768px) {
    height: 80px;
  }*/
}

.notification-badge {
  position: absolute;
  top: 0;
  right: -4px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  font-size: 8px;
  font-weight: bold;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  padding: 0;
}

.menu-button {
  --background: rgba(0, 0, 0, 0);
  --background-hover: rgba(0, 0, 0, 0);
  --border-radius: 50px;
  width: auto;
  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    --padding-start: 0;
    --padding-end: 0;
    height: 38px;
  }

  @media (min-width: 768px) {
    --padding-start: 10px;
    --padding-end: 10px;
    height: 60px;
  }
}

.logo {
  @media (max-width: 360px) {
    max-width: 105px;
  }
}
</style>
