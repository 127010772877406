<template>
  <div class="notification" @click="openNotification(notification)">
    <component :is="notification.type" :notification="notification" />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { IonImg, IonAvatar, IonText } from '@ionic/vue'

import Like from '@/components/Notification/Like'
import Follow from '@/components/Notification/Follow'
import Comment from '@/components/Notification/Comment'
import Birthday from '@/components/Notification/Birthday'
import Message from '@/components/Notification/Message'
import vaccination_date from '@/components/Notification/VaccinationDate'

import useNotifications from '@/composables/notifications'

export default defineComponent({
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  components: {
    IonImg,
    IonAvatar,
    IonText,
    Like,
    Follow,
    Comment,
    Birthday,
    Message,
    vaccination_date,
  },
  setup() {
    const { openNotification } = useNotifications()

    return {
      openNotification,
    }
  },
})
</script>

<style scoped>
.notification {
  display: flex;
  align-items: center;
  border-radius: 50px;
  box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  padding: 10px 14px 10px 10px;
  cursor: pointer;
}
</style>
