import { Preferences as Storage } from '@capacitor/preferences'

export default function useStorage() {
  async function setStorage(key, value) {
    await Storage.set({
      key,
      value: JSON.stringify(value),
    })
  }

  async function getStorage(key) {
    const item = await Storage.get({ key })

    try {
      return JSON.parse(item.value)
    } catch (error) {
      clearStorage()
    }
  }

  async function removeStorage(key) {
    await Storage.remove({ key })
  }

  async function clearStorage() {
    await Storage.clear()
  }

  return {
    getStorage,
    setStorage,
    removeStorage,
    clearStorage,
  }
}
