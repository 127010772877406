<template>
  <ion-header translucent>
    <ion-toolbar class="container">
      <ion-buttons slot="start" class="ion-justify-content-start">
        <ion-back-button
          v-if="canGoBack && hasHistory"
          mode="md"
          :defaultHref="LOGIN_PATH"
          :icon="require('@/assets/images/icons/back.svg')"
        ></ion-back-button>
        <ion-menu-button v-else auto-hide="false" class="menu-button">
          <PetAvatarIcon :avatar="require('@/assets/images/icons/dox-icon.png')" name="Doxocial" color="green" />
        </ion-menu-button>
      </ion-buttons>

      <ion-buttons slot="primary" class="ion-justify-content-end">
        <ion-button class="notification-icon" @click="navigateTo({ name: LOGIN_ROUTE })">
          <ion-img :src="require('@/assets/images/icons/notification.svg')" alt="Notification icon" />
          <ion-badge color="primary" class="notification-badge">1</ion-badge>
        </ion-button>
      </ion-buttons>

      <ion-title>
        <ion-img
          :src="require('@/assets/images/logo.svg')"
          alt="logo"
          @click="navigateTo({ name: LOGIN_ROUTE })"
          class="logo"
        />
      </ion-title>
    </ion-toolbar>
  </ion-header>
</template>

<script>
import { defineComponent, computed, watch, ref } from 'vue'

import {
  IonHeader,
  IonTitle,
  IonToolbar,
  IonImg,
  IonButtons,
  IonMenuButton,
  IonBackButton,
  IonButton,
  IonBadge,
} from '@ionic/vue'

import { useRoute } from 'vue-router'

import useNavigation from '@/composables/navigation'
import { LOGIN_ROUTE, LOGIN_PATH } from '@/constants/routes'

import PetAvatarIcon from '@/components/Pet/PetAvatarIcon'

export default defineComponent({
  name: 'GuestHeader',
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonImg,
    IonButtons,
    IonMenuButton,
    IonBackButton,
    IonButton,
    IonBadge,
    PetAvatarIcon,
  },
  setup() {
    const route = useRoute()
    const { navigateTo } = useNavigation()

    const hasHistory = ref(false)
    const canGoBack = computed(() => route.meta.canGoBack)

    watch(
      () => route.name,
      () => {
        hasHistory.value = true
      },
    )

    return {
      navigateTo,
      canGoBack,
      LOGIN_ROUTE,
      LOGIN_PATH,
      hasHistory,
    }
  },
})
</script>

<style lang="scss" scoped>
ion-buttons {
  @media (max-width: 767px) {
    width: 40px;
  }

  @media (min-width: 768px) {
    width: 20%;
  }
}

.container {
  display: flex;

  @media (max-width: 767px) {
    height: 56px;
  }

  @media (min-width: 768px) {
    height: 80px;
  }
}

.notification-badge {
  position: absolute;
  top: 0;
  right: -4px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  font-size: 8px;
  font-weight: bold;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  padding: 0;
}

.menu-button {
  --background: rgba(0, 0, 0, 0);
  --background-hover: rgba(0, 0, 0, 0);
  --border-radius: 50px;
  width: auto;

  @media (max-width: 767px) {
    --padding-start: 0;
    --padding-end: 0;
    height: 38px;
  }

  @media (min-width: 768px) {
    --padding-start: 10px;
    --padding-end: 10px;
    height: 60px;
  }
}

.active-pet {
  border: 2px solid red;
  padding: 2px;

  @media (max-width: 767px) {
    width: 38px;
    height: 38px;
  }

  @media (min-width: 768px) {
    width: 44px;
    height: 44px;
  }

  &.missing {
    border-color: #f64242;
  }

  &.is-safe {
    border-color: #a3ffc8;
  }

  &-name {
    @media (max-width: 767px) {
      display: none;
    }

    @media (min-width: 76px) {
      margin-left: 10px;
      font-size: 1rem;
      font-weight: 700;
    }
  }
}
</style>
