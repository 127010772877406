<template>
  <div id="splash">
    <ion-img class="lottie" :src="require('@/assets/images/animated/splash-anim.gif')" />
  </div>
  <ion-img :src="require('@/assets/images/logo.svg')" alt="logo" class="logo" />
</template>

<style lang="scss" scoped>
#splash {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fdfef7;

  .lottie {
    margin-bottom: 50px;
    max-width: 80%;
  }
}

.logo {
  position: absolute;
  left: 50%;
  bottom: 50px;
  transform: translateX(-50%);
}
</style>
