import { readonly, computed } from 'vue'

import { useRegistrationState } from '@/store/registration'
import useAuth from '@/composables/auth'
import useNavigation from '@/composables/navigation'
import { REGISTRATION_ROUTE } from '@/constants/routes'

export default function useRegistration() {
  const REGISTRATION_STATE = useRegistrationState()
  const { isAuthenticated } = useAuth()
  const { navigateTo } = useNavigation()

  const steps = ['UserData', 'PetData', 'PetBreed', 'PetProfile', 'PetAbout', 'UserPhoto']

  const authenticatedSteps = ['PetData', 'PetBreed', 'PetProfile', 'PetAbout', 'UserPhoto']

  const doxSteps = [
    isAuthenticated.value ? 'PetSelect' : 'UserData',
    'PetData',
    'PetBreed',
    'PetProfile',
    'PetAbout',
    'HalfAnimation',
    'PetLost',
    'UserPhoto',
    'PetHealth',
    'PetChip',
    'PetVaccinationDate',
    'PetVaccination',
    'PetPedigree',
    'PetPassport',
  ]

  const alreadyRegisteredDoxSteps = [
    'PetSelect',
    'PetLost',
    'UserPhoto',
    'PetHealth',
    'PetChip',
    'PetVaccinationDate',
    'PetVaccination',
    'PetPedigree',
    'PetPassport',
  ]

  function setPet(pet) {
    REGISTRATION_STATE.pet = Object.assign(REGISTRATION_STATE.pet, pet)
  }

  const getPet = computed(() => REGISTRATION_STATE.pet)
  const removePet = () => (REGISTRATION_STATE.pet = {})

  const setDox = hash => (REGISTRATION_STATE.dox = hash)
  const isDoxRead = computed(() => REGISTRATION_STATE.dox)
  const clearCurrentDox = () => (REGISTRATION_STATE.dox = '')

  const setReadOs = os => (REGISTRATION_STATE.os = os)
  const getReadOs = computed(() => REGISTRATION_STATE.os)

  const setReadLatitude = latitude => (REGISTRATION_STATE.latitude = latitude)
  const getReadLatitude = computed(() => REGISTRATION_STATE.latitude)
  const setReadLongtitude = longtitude => (REGISTRATION_STATE.longtitude = longtitude)
  const getReadLongtitude = computed(() => REGISTRATION_STATE.longtitude)

  const setReadCountry = country => (REGISTRATION_STATE.country = country)
  const getReadCountry = computed(() => REGISTRATION_STATE.country)

  const setReadCity = city => (REGISTRATION_STATE.city = city)
  const getReadCity = computed(() => REGISTRATION_STATE.city)
  const setReadAddress = address => (REGISTRATION_STATE.address = address)
  const getReadAdress = computed(() => REGISTRATION_STATE.address)

  function forceCreatePet() {
    navigateTo({ name: REGISTRATION_ROUTE })
  }

  function setUserPhoto(user) {
    REGISTRATION_STATE.user = Object.assign(REGISTRATION_STATE.user, user)
  }

  const getUserPhoto = computed(() => REGISTRATION_STATE.user)

  return {
    REGISTRATION_STATE: readonly(REGISTRATION_STATE),
    setPet,
    getPet,
    removePet,
    setDox,
    isDoxRead,
    clearCurrentDox,
    steps,
    authenticatedSteps,
    doxSteps,
    alreadyRegisteredDoxSteps,
    forceCreatePet,
    setUserPhoto,
    getUserPhoto,
    setReadOs,
    getReadOs,
    setReadLatitude,
    getReadLatitude,
    setReadLongtitude,
    getReadLongtitude,
    setReadCountry,
    getReadCountry,
    setReadCity,
    getReadCity,
    setReadAddress,
    getReadAdress,
  }
}
