import {
  FEED_ROUTE,
  FEED_PATH,
  POST_ROUTE,
  POST_PATH,
  POST_CREATE_PATH,
  POST_CREATE_ROUTE,
  POST_EDIT_ROUTE,
  POST_EDIT_PATH,
} from '@/constants/routes'

export default [
  {
    path: FEED_PATH,
    name: FEED_ROUTE,
    component: () => import(/* webpackChunkName: "feed" */ '@/views/Posts/Index.vue'),
    meta: {
      authenticatedOnly: true,
    },
  },
  {
    name: POST_ROUTE,
    path: POST_PATH,
    component: () => import(/* webpackChunkName: "feed" */ '@/views/Posts/PostPage.vue'),
    meta: {
      authenticatedOnly: true,
      canGoBack: true,
    },
  },
  {
    name: POST_CREATE_ROUTE,
    path: POST_CREATE_PATH,
    component: () => import(/* webpackChunkName: "feed" */ '@/views/Posts/Create.vue'),
    meta: {
      authenticatedOnly: true,
      canGoBack: true,
    },
  },
  {
    name: POST_EDIT_ROUTE,
    path: POST_EDIT_PATH,
    component: () => import(/* webpackChunkName: "feed" */ '@/views/Posts/Create.vue'),
    meta: {
      authenticatedOnly: true,
      canGoBack: true,
    },
  },
]
