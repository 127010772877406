import { useRouter } from 'vue-router'

import { Browser } from '@capacitor/browser'

export default function useNavigation() {
  const router = useRouter()

  async function navigateTo(route) {
    await router.push(route)
  }

  async function openBrowserLink(url) {
    await Browser.open({ url })
  }

  async function openLink(url) {
    await window.open(url, '_system')
  }

  async function replace(route) {
    await router.replace(route)
  }

  return {
    replace,
    navigateTo,
    openBrowserLink,
    openLink,
  }
}
