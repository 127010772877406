import moment from 'moment'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { menuController } from '@ionic/vue'

import httpClient from '@/core/api/api.service'
import useNavigation from '@/composables/navigation'
import usePets from '@/composables/pets'

import {
  SET_NOTIFICATIONS,
  SET_NOTIFICATION_READ,
  SET_ALL_NOTIFICATIONS_READ,
  SET_NOTIFICATIONS_READ_AT,
} from '@/constants/types'

export default function useNotifications() {
  const store = useStore()
  const { navigateTo } = useNavigation()
  const { PETS_STATE } = usePets()
  const isLoading = ref(false)

  const activePetId = computed(() => PETS_STATE.activePetId)
  const getNewNotifications = computed(() => store.getters.getNewNotifications)
  const filterNotificationsByType = (notifications, type) =>
    notifications.filter(notification => (type === 'all' ? notification.type !== 'follow' : notification.type === type))
  const getReadNotifications = () => store.getters.getReadNotifications

  function filterNotificationsByDay(notifications, { minDay, maxDay }) {
    const fromDate = moment().subtract(maxDay, 'days').format()
    const toDate = moment().subtract(minDay, 'days').format()
    return notifications.filter(
      notification => moment(notification.date).isAfter(fromDate) && moment(notification.date).isBefore(toDate),
    )
  }

  function setNotificationRead(notification) {
    store.commit(SET_NOTIFICATION_READ, notification)
  }

  async function setAllNotificationsRead() {
    try {
      store.commit(SET_NOTIFICATIONS_READ_AT, moment().format())
      await httpClient.patch(`pets/${activePetId.value}/notifications`)
      store.commit(SET_ALL_NOTIFICATIONS_READ)
    } catch (error) {
      throw new Error(`${JSON.stringify(error)}`)
    }
  }

  async function fetchNotifications() {
    if (isLoading.value) {
      return
    }

    try {
      isLoading.value = true

      const { data } = await httpClient.get(`pets/notifications`)

      const notifications = data.data
      store.commit(SET_NOTIFICATIONS, notifications)

      return Promise.resolve()
    } catch (error) {
      return Promise.reject()
    } finally {
      isLoading.value = false
    }
  }

  async function fetchNotificationsForActivePet() {
    if (isLoading.value) {
      return
    }

    try {
      isLoading.value = true

      const { data } = await httpClient.get(`pets/${activePetId.value}/notifications`)

      const notifications = data.data
      store.commit(SET_NOTIFICATIONS, notifications)

      return Promise.resolve()
    } catch (error) {
      return Promise.reject()
    } finally {
      isLoading.value = false
    }
  }

  async function openNotification(notification) {
    try {
      if (!notification.readAt) {
        await httpClient.patch(`pets/${activePetId.value}/notifications`, notification.id)
        setNotificationRead(notification)
      }

      menuController.close()

      switch (notification.type) {
        case 'follow':
          navigateTo({ name: 'pet', params: { id: notification.pet.id } })
          break
        case 'birthday':
          navigateTo({ name: 'pet', params: { id: notification.pet?.id ?? activePetId.value } })
          break
        case 'comment':
        case 'like':
          navigateTo({
            name: 'post',
            params: {
              id: notification.post.id,
            },
          })
          break
      }

      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const getNotificationDate = notification => moment(notification.date).locale('hu').fromNow()

  return {
    NOTIFICATION_STATE: store.state.notification,
    getNewNotifications,
    filterNotificationsByType,
    getReadNotifications,
    filterNotificationsByDay,
    setNotificationRead,
    setAllNotificationsRead,
    fetchNotifications,
    fetchNotificationsForActivePet,
    openNotification,
    isLoading,
    getNotificationDate,
  }
}
