export const AUTH_REQUEST = 'auth_request'
export const AUTH_LOGOUT = 'auth_logout'
export const AUTH_SUCCESS = 'auth_success'
export const AUTH_ERROR = 'auth_error'

export const MAPS_API = 'AIzaSyDgMwRZAvXjyEqU05q-myM2G2CqkDVUfOo'
export const MAPS_API_IOS = 'AIzaSyDgMwRZAvXjyEqU05q-myM2G2CqkDVUfOo'
export const MAPS_API_ANDROID = 'AIzaSyDgMwRZAvXjyEqU05q-myM2G2CqkDVUfOo'

export const GET_AUTH_USER = 'get_auth_user'
export const SET_AUTH_USER = 'set_auth_user'
export const SET_AUTH_USER_STATUS = 'set_auth_user_status'
export const SET_AUTH_TOKEN = 'set_auth_token'

export const SET_PETS = 'set_pets'
export const SET_ACTIVE_PET = 'set_active_pet'
export const SET_PET_ACTIVE = 'set_pet_active'
export const SET_ACTIVE_PET_STATUS = 'set_active_pet_status'
export const UPDATE_PET = 'update_pet'
export const DELETE_PET = 'delete_pet'
export const SET_PET_OPTIONS = 'set_pet_options'
export const SET_PETS_WALKING = 'set_pets_walking'
export const SET_ACTIVE_WALKING = 'set_active_walking'

export const SET_NOTIFICATIONS = 'set_notifications'
export const GET_NOTIFICATIONS = 'get_notifications'
export const SET_NOTIFICATION_READ = 'set_notification_read'
export const SET_ALL_NOTIFICATIONS_READ = 'SET_ALL_NOTIFICATIONS_READ'
export const SET_NOTIFICATIONS_READ_AT = 'set_notifications_read_at'

export const SET_REGISTRATION_DOX = 'set_registration_dox'
export const CLEAR_REGISTRATION_DOX = 'clear_registration_dox'
export const SET_REGISTRATION_PET = 'set_registration_pet'
export const CLEAR_REGISTRATION_PET = 'clear_registration_pet'

export const TOGGLE_PET_MENU = 'toggle_pet_menu'
