import { createStore } from 'vuex'

import app from './modules/app'
import auth from './modules/auth'
import notification from './modules/notification'
import pet from './modules/pet'

const debug = process.env.NODE_ENV !== 'production'

export default createStore({
  modules: {
    app,
    auth,
    notification,
    pet,
  },
  strict: debug,
})
