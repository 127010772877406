import { ref, computed } from 'vue'

import httpClient from '@/core/api/api.service'
import useGeolocation from '@/composables/geolocation'
import useToast from '@/composables/toast'
import usePets from '@/composables/pets'
import { useRoute } from 'vue-router'
// import { Capacitor } from '@capacitor/core'

import { SNIFF_SETTINGS, SNIFF_DISTANCE } from '@/constants/apiRoutes'
import { PET_FINDER_ROUTE } from '@/constants/routes'
import useNavigation from '@/composables/navigation'

const DEFAULT_PET_FINDER_INTERVAL = 30000 // 30sec
const GENERAL_PET_FINDER_INTERVAL = 900000 // 15min
const DEFAULT_PET_FINDER_PARAMS = { response_type: 'pets' }
const GENERAL_PET_FINDER_PARAMS = {}

const callbackInterval = ref(DEFAULT_PET_FINDER_INTERVAL)
const callbackParams = ref(DEFAULT_PET_FINDER_PARAMS)
const serviceInstance = ref(null)

export default function usePetFinder() {
  const { getCurrentPosition, askToTurnOnLocationServices, checkLocationServiceRequirements } = useGeolocation()
  const { openToast } = useToast()
  const { activePet } = usePets()
  const route = useRoute()
  const { navigateTo } = useNavigation()

  const pets = ref([])
  const isServiceRunning = computed(() => serviceInstance.value)
  const isLoading = ref(false)
  const serviceError = ref(0)
  const dataLoaded = ref(false)

  const setCallbackInterval = (newInterval = DEFAULT_PET_FINDER_INTERVAL) => (callbackInterval.value = newInterval)
  const setCallbackParams = (newParams = DEFAULT_PET_FINDER_PARAMS) => (callbackParams.value = newParams)

  const saveLocationData = async () => {
    try {
      const position = await getCurrentPosition()
      const currentPosition = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      }

      const sniffSettings = await initSniffSettings()

      if (sniffSettings) {
        callbackParams.value['sniff-max-distance'] = sniffSettings.data.data.szimat_range * 1000
        callbackParams.value['sniff-max-pets'] = sniffSettings.data.data.szimat_max_pets

        const { data } = await httpClient.save('location', currentPosition, callbackParams.value)

        if (data.data) {
          pets.value = data.data
        }

        dataLoaded.value = true

        serviceError.value = 0
      }

      return Promise.resolve()
    } catch (error) {
      if (serviceError.value++ > 3) {
        stopService()
        return Promise.reject(new Error('Location error limit reached'))
      }

      pets.value = []
      return Promise.resolve()
    }
  }

  const initService = async () => {
    if (isLoading.value) {
      return
    }

    isLoading.value = true

    try {
      await saveLocationData()
    } catch (error) {
      stopService()
      console.log(error)
      await openToast({
        message: 'Ahhoz, hogy a SZIMAT funkciót használni tudd, engedélyezned kell a helyadatokhoz való hozzáférést.',
        color: 'warning',
      })
      throw new Error(`Failed to initialize location service`)
    } finally {
      isLoading.value = false
    }
  }

  const resetService = async () => {
    if (isServiceRunning.value) {
      stopService()
      startService()
    }
  }

  const startService = async () => {
    // if (!Capacitor.isNative) {
    //   await openToast({ message: 'A funkció használatához töltsd le a mobil alkalmazást.' })
    //   return
    // }

    if (isServiceRunning.value) {
      return
    }

    pets.value = []
    serviceError.value = 0
    isLoading.value = false

    try {
      await checkLocationServiceRequirements()
      await askToTurnOnLocationServices()

      initService()
      serviceInstance.value = setInterval(initService, callbackInterval.value)
    } catch (error) {
      console.log(error)
      await openToast({
        message: 'Ahhoz, hogy a SZIMAT funkciót használni tudd, engedélyezned kell a helyadatokhoz való hozzáférést.',
        color: 'warning',
      })
      throw new Error('Failed to start location service')
    }
  }

  const stopService = () => {
    clearInterval(serviceInstance.value)
    dataLoaded.value = false
    serviceInstance.value = null
    pets.value = []
    serviceError.value = 0
    isLoading.value = false
  }

  const initSniffSettings = async () => {
    return await httpClient.get(SNIFF_SETTINGS)
  }

  const saveFinderSettings = async (distance, numberOfPets) => {
    let params = {}
    params['sniff-max-range'] = distance
    params['sniff-max-pets'] = numberOfPets

    await httpClient
      .update(SNIFF_SETTINGS, '', '', params)
      .then(() => {
        navigateTo({ name: PET_FINDER_ROUTE })
      })
      .catch(err => {
        openToast({
          message: err.errData.error,
          color: 'danger',
        })
      })
  }

  const getSniffDistance = async () => {
    if (!activePet) {
      return false
    }

    let params = {
      ownPetId: activePet?.value?.id,
      targetPetId: route?.params?.id,
    }

    let queryString = '' + params.targetPetId + '?own-pet=' + params.ownPetId

    return await httpClient.get(SNIFF_DISTANCE, queryString)
  }

  return {
    pets,
    callbackInterval,
    resetService,
    startService,
    stopService,
    isServiceRunning,
    setCallbackInterval,
    setCallbackParams,
    GENERAL_PET_FINDER_INTERVAL,
    GENERAL_PET_FINDER_PARAMS,
    serviceError,
    dataLoaded,
    initSniffSettings,
    saveFinderSettings,
    getSniffDistance,
  }
}
