import { ref, reactive, toRefs } from 'vue'
import { menuController } from '@ionic/vue'

import httpClient from '@/core/api/api.service'
import helpers from '@/utils/helpers'
import { FEED_ROUTE } from '@/constants/routes'
import useNavigation from '@/composables/navigation'

const feed = reactive({
  posts: [],
  hasNextPage: true,
  nextPage: 1,
})

export default function usePosts() {
  const { navigateTo } = useNavigation()

  const hasError = ref(false)
  const isLoading = ref(false)

  function toggleIsLoading() {
    isLoading.value = !isLoading.value
  }

  function updatePosts(data) {
    feed.posts = helpers.appendToList(feed.posts, data)
  }

  function setNextPage(currentPage) {
    feed.nextPage = currentPage + 1
  }

  function toggleHasNextPage() {
    feed.hasNextPage = !feed.hasNextPage
  }

  async function fetchPosts() {
    if (isLoading.value) return

    try {
      toggleIsLoading()

      const params = {
        page: feed.nextPage,
        limit: 10,
        extended: true,
      }

      const { data } = await httpClient.get('posts', '', params)

      const posts = data.data
      const pages = data.pagination

      if (feed.nextPage > 1) {
        updatePosts(posts)
      } else {
        feed.posts = posts
      }

      if (pages.lastPage > pages.currentPage) {
        setNextPage(pages.currentPage)
      } else {
        toggleHasNextPage()
      }

      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    } finally {
      toggleIsLoading()
    }
  }

  async function loadPosts(event) {
    await fetchPosts()
    event.target.complete()
  }

  function resetFeed() {
    feed.posts = []
    feed.hasNextPage = true
    feed.nextPage = 1
  }

  async function fetchPost(id) {
    try {
      const { data } = await httpClient.get('posts', id)
      return Promise.resolve(data.data)
    } catch (error) {
      hasError.value = true
      return Promise.reject(error)
    }
  }

  async function createPost(post) {
    try {
      await httpClient.save('posts', post)
      goToFeed()

      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async function updatePost(post) {
    try {
      await httpClient.patch('posts', post.id, post)
      resetFeed()
      goToFeed()

      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async function deletePost(post) {
    try {
      await httpClient.remove('posts', post)
      goToFeed()

      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async function reportPost(id) {
    try {
      await httpClient.save(`posts/${id}/report`)

      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async function goToFeed() {
    const posts = document.querySelector('.posts')
    menuController.close()
    await navigateTo({ name: FEED_ROUTE })
    setTimeout(() => {
      posts && posts.firstElementChild.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
    }, 100)
    feed.hasNextPage = true
    feed.nextPage = 1
    await fetchPosts()
  }

  return {
    loadPosts,
    fetchPosts,
    fetchPost,
    ...toRefs(feed),
    isLoading,
    createPost,
    updatePost,
    deletePost,
    reportPost,
    resetFeed,
    hasError,
    goToFeed,
  }
}
