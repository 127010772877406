import { createRouter, createWebHistory } from '@ionic/vue-router'

import AuthService from '@/core/auth/services/auth.service'

import authRoutes from './modules/auth'
import feedRoutes from './modules/feed'
import petRoutes from './modules/pet'
import registrationRoutes from './modules/registration'
import pageRoutes from './modules/pages'
import userRoutes from './modules/user'

import PageNotFound from '@/views/PageNotFound'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    ...feedRoutes,
    ...authRoutes,
    ...petRoutes,
    ...registrationRoutes,
    ...pageRoutes,
    ...userRoutes,
    { path: '/:pathMatch(.*)*', name: 'not-found', component: PageNotFound },
  ],
})

router.beforeEach((to, from, next) => AuthService.ensureAuthenticated(to, from, next))
router.beforeEach((to, from, next) => AuthService.ensureUnauthenticated(to, from, next))

export default router
