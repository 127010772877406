import moment from 'moment'
import lodash from 'lodash'
import {
  getAuthToken,
  setAuthToken,
  removeAuthToken,
  getAuthTokenExpiry,
  setAuthTokenExpiry,
  removeAuthTokenExpiry,
} from './token.service'

import { FEED_ROUTE, LOGIN_ROUTE } from '@/constants/routes'
import useStorage from '@/composables/storage'

const { getStorage, setStorage, removeStorage } = useStorage()
const AUTH_USER_KEY = 'auth_user'

const getAuthUser = () =>
  getStorage(AUTH_USER_KEY) && !lodash.isEmpty(getStorage(AUTH_USER_KEY)) ? JSON.parse(getStorage(AUTH_USER_KEY)) : {}
const setAuthUser = user => setStorage(AUTH_USER_KEY, user)
const removeAuthUser = () => removeStorage(AUTH_USER_KEY)

const ensureAuthenticated = async (to, from, next) => {
  const authToken = await getAuthToken()
  const authTokenExpiry = await getAuthTokenExpiry()
  const isAuthenticated = !!authToken && moment().isBefore(authTokenExpiry)

  const canAccessRoute = to.name === LOGIN_ROUTE ? '' : to.name

  const loginCallbackRoute = canAccessRoute
    ? { name: LOGIN_ROUTE }
    : {
        name: LOGIN_ROUTE,
        query: { redirect: to.fullPath },
      }

  if (to.matched.some(record => record.meta.authenticatedOnly) && !isAuthenticated) {
    return next(loginCallbackRoute)
  }

  return next()
}

const ensureUnauthenticated = async (to, from, next) => {
  const authToken = await getAuthToken()
  const authTokenExpiry = await getAuthTokenExpiry()
  const isAuthenticated = !!authToken && moment().isBefore(authTokenExpiry)

  if (isAuthenticated && to.matched.some(record => record.meta.unauthenticatedOnly)) {
    return next(from.matched.length ? false : { name: FEED_ROUTE })
  }

  return next()
}

export default {
  getAuthToken,
  setAuthToken,
  removeAuthToken,
  getAuthTokenExpiry,
  setAuthTokenExpiry,
  removeAuthTokenExpiry,
  ensureAuthenticated,
  ensureUnauthenticated,
  getAuthUser,
  setAuthUser,
  removeAuthUser,
}
