export default {
  INACTIVE: {
    id: 0,
    name: 'Inactive',
  },
  ACTIVE: {
    id: 1,
    name: 'Active',
  },
  MISSING: {
    id: 2,
    name: 'Missing',
  },
}
