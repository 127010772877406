<template>
  <div ref="menuEl" :class="['menu-parent', { menuActive: isActive }]">
    <div class="menu">
      <div class="menu-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, onMounted } from 'vue'
import { createGesture } from '@ionic/vue'
export default {
  name: 'TopArchMenu',
  components: {},
  props: {
    isActive: Boolean,
    menuHeight: String,
    canClose: Boolean,
  },
  emits: ['menuClosed'],
  setup(props, { emit }) {
    const menuHeightComp = computed(() => (props.menuHeight ? `${props.menuHeight}px` : '230px'))
    const menuEl = ref(props.isActive)

    onMounted(() => {
      // console.log(menuEl.value)
      // const style = menuEl.value.style
      // console.log(style)
      const option = {
        el: menuEl.value,
        gestureName: 'close-down',
        direction: 'y',
        onStart: () => {
          // style.transition = 'none'
        },
        onMove: () => {
          // style.transform = `translateY(${ev.deltaY}px)`
        },
        onEnd: ev => {
          if (props.canClose && ev.deltaY > 50) {
            emit('menuClosed', true)
          }
          // style.transition = '0.3s ease-out'
          // if (ev.deltaY > menuHeightComp.value / 2) {
          //   style.transform = `translateY(${menuHeightComp.value * 1.5}px)`
          //   emit('menuClosed', true)
          // } else {
          //   style.transform = ''
          // }
        },
      }

      const gesture = createGesture(option)

      gesture.enable()
    })
    return {
      props,
      emit,
      menuHeightComp,
      menuEl,
    }
  },
}
</script>

<style lang="scss" scoped>
.menu-parent {
  position: absolute;
  top: -200%;
  width: 125%;
  transform: translateX(-9%);
  min-height: 150px;
  transition: all 0.7s ease-in-out;

  .menu {
    position: absolute;
    z-index: 5;
    border-radius: 0 0 50% 50%;
    overflow: hidden;
    background: var(--ion-item-background, var(--ion-background-color, #fff));
    box-shadow: 0 0 0 10px #b1b1b1;
    width: 100%;
    padding-bottom: 200px;
    min-height: 150px;

    @media only screen and (max-width: 500px) {
      padding-bottom: 120px;
    }
  }

  .menu-content {
    position: relative;
    width: 100vw;
    max-width: 600px;
    margin: 0 auto;
    font-size: 0.8rem !important;

    button {
      font-family: var(--ion-poppins-font);
      font-size: 12px;
      letter-spacing: 0.03571em;
    }
  }

  &.menuActive {
    top: var(--header-height-web);

    @media only screen and (max-width: 600px) {
      top: var(--header-height-mobile);
    }
  }
}

@media only screen and (max-width: 600px) {
  .ios .menuActive {
    top: 115px;
  }
}
</style>
