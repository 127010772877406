import { ref, reactive } from 'vue'
import { Geolocation } from '@ionic-native/geolocation'
import { AndroidPermissions } from '@ionic-native/android-permissions'
import { LocationAccuracy } from '@ionic-native/location-accuracy'

const INITIAL_COORDS = {
  latitude: 47.4810954,
  longitude: 18.9654958,
}

export default function useGeolocation() {
  const timestamp = ref(null)
  const locationError = ref(null)
  const positionOptions = ref({})
  const coords = reactive({ ...INITIAL_COORDS })
  let watchId = 0

  const canRequestLocationAccuracy = async () => {
    return await new Promise((resolve, reject) => {
      LocationAccuracy.canRequest().then(canRequest => {
        if (!canRequest) {
          reject()
        }

        resolve()
      })
    })
  }

  const checkLocationServicesPermission = async () => {
    return await new Promise((resolve, reject) => {
      AndroidPermissions.checkPermission(AndroidPermissions.PERMISSION.ACCESS_FINE_LOCATION).then(
        result => resolve(result.hasPermission),
        error => {
          reject(new Error(`Error requesting location permissions ${JSON.stringify(error)}`))
        },
      )
    })
  }

  const requestLocationServicesPermission = async () =>
    await new Promise((resolve, reject) => {
      AndroidPermissions.requestPermission(AndroidPermissions.PERMISSION.ACCESS_FINE_LOCATION).then(
        result => {
          if (result.hasPermission) {
            resolve()
          } else {
            reject()
          }
        },
        error => {
          reject(new Error(`Error requesting location permissions ${JSON.stringify(error)}`))
        },
      )
    })

  const askToTurnOnLocationServices = async () =>
    await new Promise((resolve, reject) => {
      LocationAccuracy.request(LocationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY).then(
        () => {
          Object.assign(positionOptions.value, { enableHighAccuracy: true })
          // console.log('position enable high accuracy')
          resolve()
        },
        () => {
          LocationAccuracy.request(LocationAccuracy.REQUEST_PRIORITY_BALANCED_POWER_ACCURACY).then(
            () => {
              Object.assign(positionOptions.value, { enableHighAccuracy: false })
              resolve()
            },
            error => {
              reject(new Error(`Error requesting location permissions ${JSON.stringify(error)}`))
            },
          )
        },
      )
    })

  const checkLocationServiceRequirements = async () => {
    try {
      const canRequest = canRequestLocationAccuracy()
      if (canRequest) {
        return Promise.resolve()
      }

      const hasRequiredPermission = await checkLocationServicesPermission()
      if (hasRequiredPermission) {
        return Promise.resolve()
      }

      const gotRequiredPermission = await requestLocationServicesPermission()
      if (gotRequiredPermission) {
        return Promise.resolve()
      }
    } catch (error) {
      return Promise.reject(new Error(`Error checking location service requirements ${JSON.stringify(error)}`))
    }
  }

  function updatePosition(position) {
    timestamp.value = position.timestamp
    coords.latitude = position.coords.latitude
    coords.longitude = position.coords.longitude
    locationError.value = null
  }

  const getCurrentPosition = async testPosition => {
    try {
      if (testPosition) {
        testPosition.coords.latitude += 0.0001
        testPosition.coords.longitude += 0.0001
      }
      const position = testPosition ? testPosition : await Geolocation.getCurrentPosition(positionOptions.value)
      // console.log('position from getcurrent:', position.coords.latitude, position.coords.longitude)
      updatePosition(position)
      return Promise.resolve(position)
    } catch (error) {
      handleError(error)
    }
  }

  function handleError(error) {
    watchId = 0
    timestamp.value = Date.now()
    locationError.value = error
    Object.assign(coords, { ...INITIAL_COORDS })
    if (error.message == 'User denied Geolocation' || error.message == 'User denied geolocation prompt') {
      throw new Error('User denied Geolocation')
    } else {
      throw new Error('Unable to retrieve user location')
    }
  }

  // telefon energia felhasználás szempontjából optimális
  // csak akkor frissít ha mozgásban van a készülék
  // tesztek alapján ios-en késik, ezért a getcoords lesz helyette
  // időközönként
  function watchPosition(testPosition) {
    // console.log('watchpos watchid', watchId)
    if (!watchId) {
      console.log('watchpos start', testPosition)
      if (testPosition) {
        watchId = setInterval(() => {
          testPosition.coords.latitude += 0.0001
          testPosition.coords.longitude += 0.0001
          updatePosition(testPosition)
        }, 1500)
      } else {
        watchId = Geolocation.watchPosition(positionOptions.value).subscribe(
          position => {
            // console.log('watchpos running', position.coords.latitude, position.coords.longitude)
            updatePosition(position)
          },
          error => {
            handleError(error)
          },
        )
      }
    } else {
      console.log('watchpos already running')
    }
  }

  // const clearWatchPosition = () => watchId && Geolocation.clearWatch({ id: watchId })
  const clearWatchPosition = testPosition => {
    if (watchId) {
      if (testPosition) {
        clearInterval(watchId)
      } else {
        if (watchId) {
          try {
            // Geolocation.clearWatch(watchId)
            console.log('stop watching current pos:', watchId)
            watchId.unsubscribe()
          } catch (error) {
            console.error(error)
          }
        }
      }
      watchId = 0
    }
  }

  return {
    coords,
    timestamp,
    locationError,
    getCurrentPosition,
    askToTurnOnLocationServices,
    checkLocationServiceRequirements,
    watchPosition,
    clearWatchPosition,
  }
}
