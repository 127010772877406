<template>
  <div class="backdrop" v-if="isPetMenuOpened" @click="togglePetMenu(false)"></div>
  <TopArchMenu
    class="topMenu"
    :menuHeight="383"
    v-bind:isActive="isPetMenuOpened"
    :canClose="isRunning"
    :backdrop="true"
  >
    <ion-list class="menu-list ion-no-padding">
      <ion-item class="menu-item menu-item--pet-list" lines="none">
        <PetList />
      </ion-item>
      <div class="hr"></div>
      <ion-item v-if="activePet" class="menu-item link" lines="none" @click="goToPet">
        <ion-img :src="require('@/assets/images/icons/edit.svg')" alt="Profil módosítása" class="menu-icon" />
        <div>
          <h2>Profil módosítása</h2>
          <b>{{ activePet.name }}</b> adatainak szerkesztése
        </div>
      </ion-item>
      <ion-item class="menu-item menu-item link" lines="none" @click="goToSettings">
        <ion-img :src="require('@/assets/images/icons/petmenu_settingsicon.svg')" alt="Beállítások" class="menu-icon" />
        <div>
          <h2>Beállítások</h2>
          Applikációs beállítások
        </div>
      </ion-item>
      <ion-item class="menu-item menu-item link" lines="none" @click="goToAbout">
        <ion-img :src="require('@/assets/images/icons/petmenu_infoicon.svg')" alt="Névjegy" class="menu-icon" />
        <div>
          <h2>Névjegy</h2>
          App információk
        </div>
      </ion-item>
    </ion-list>
    <lottie-animation
      ref="anim"
      :autoPlay="false"
      :playInterval="0"
      :playOnClick="true"
      :onClick="clickOnPaw"
      :animationData="require('@/assets/images/lottie/petmenu-paw.json')"
      class="lottie-anim"
    />
  </TopArchMenu>
</template>

<script>
import { defineComponent, computed, watch } from 'vue'
import { IonList, IonItem, menuController, isPlatform } from '@ionic/vue'

import TopArchMenu from '@/components/Global/TopArchMenu'
import PetList from '@/components/Navigation/PetList'

import { PAGE_ABOUT_ROUTE, PAGE_SETTINGS_ROUTE } from '@/constants/routes'
import PET_STATUS from '@/constants/pet-status'

import useApp from '@/composables/app'
import usePets from '@/composables/pets'
import useNavigation from '@/composables/navigation'
import { PET_EDIT_ROUTE } from '@/constants/routes'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'MainMenu',
  components: {
    TopArchMenu,
    PetList,
    IonList,
    IonItem,
  },
  setup() {
    const { isPetMenuOpened, togglePetMenu } = useApp()
    const { navigateTo, openBrowserLink } = useNavigation()
    const { PETS_STATE, activePet } = usePets()
    const route = useRoute()
    const isMissing = computed(() => activePet.value && activePet.value.status === PET_STATUS.MISSING.name)

    const hasSwipe = computed(() => !isPlatform('desktop'))

    const goToAbout = async () => {
      await menuController.close()
      navigateTo({ name: PAGE_ABOUT_ROUTE })
    }

    const goToSettings = async () => {
      await menuController.close()
      navigateTo({ name: PAGE_SETTINGS_ROUTE })
    }

    const goToPet = async () => {
      await menuController.close()
      navigateTo({ name: PET_EDIT_ROUTE, params: { id: activePet.value.id } })
    }

    const clickOnPaw = () => {
      setTimeout(() => {
        togglePetMenu(false)
      }, 750)
    }

    watch(route, () => {
      togglePetMenu(false)
    })

    return {
      isMissing,
      pets: computed(() => PETS_STATE.pets),
      activePet,
      hasSwipe,
      goToPet,
      goToAbout,
      goToSettings,
      openBrowserLink,
      isPetMenuOpened,
      togglePetMenu,
      clickOnPaw,
    }
  },
})
</script>

<style lang="scss" scoped>
.backdrop {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9;
}

.hr {
  max-width: calc(100% - 40px);
  margin: 0 auto;
  width: 100%;
  height: 1px;
  background: linear-gradient(to right, #fff, #dfdfdf 10%, #dfdfdf 90%, #fff);
}

.menu {
  color: var(--ion-color-dark);

  .btn-dox {
    width: 100%;
    font-size: 0.75rem;
    margin: 1em 0.25em 0.5em;
  }

  .btn-logout {
    display: inline-block;
    margin: 0 calc(-1 * var(--padding-start)) 0 calc(-1 * var(--padding-end));
    text-transform: initial;
    float: left;
  }

  .menu-item {
    --padding-start: 0;
    --inner-padding-end: 0;
    font-size: 14px;
    border: none;
    padding: 0 20px;

    &--pet-list {
      --inner-padding-bottom: 0 !important;
      padding: 0;
    }

    &.link {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding-top: 20px;
      font-size: 10px;
      font-weight: 600;

      h2 {
        font-weight: 800;
        font-size: 14px;
        margin: 0;
      }
    }
  }
}

.menu-icon {
  margin-right: 18px;
  max-width: 20px;
  min-width: 20px;
}

.dox-logo {
  width: 40px;
  height: 20px;
}

.btn-text {
  --background: transparent;
  font-size: 14px;
  font-weight: bold;
  --color: var(--ion-color-dark);
  --box-shadow: none;
  letter-spacing: 0;
}

.menu-text {
  margin-right: auto;
}

.pulse-circle {
  position: relative;
  width: 20px;
  height: 20px;
  margin: 15px;
  background: #2dd36f;
  border-radius: 50%;
  z-index: 10;

  &:before,
  &:after {
    position: absolute;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    background: transparent;
    border: 1px solid #28ba62;
    border-radius: 50%;
    box-shadow: 0 0 0 0 #28ba62;
    animation: pulse-menu 2s infinite;
    content: '';
  }

  &:after {
    animation-delay: 1.5s;
    transform: scale(0);
    z-index: -1;
  }

  .radar {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    background-image: linear-gradient(to left, #28ba62, transparent);
    background-repeat: no-repeat;
    background-size: 50% 100%;
    background-position: 100% 0;
    box-shadow: 0 0 20px -5px #28ba62;
    transform-origin: center;
    transform: translateY(-50%) rotate(0deg);
    animation: 1.5s moveCircle linear infinite;
    opacity: 1;
  }
}

.topMenu {
  z-index: 9;
  max-width: 600px;
  left: 50%;
  transform: translateX(-50%);

  .lottie-anim {
    position: absolute;
    bottom: -200px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 55px;

    @media only screen and (max-width: 500px) {
      bottom: -120px;
    }
  }
}

@keyframes pulse-menu {
  0% {
    opacity: 0;
    transform: scale(0);
    box-shadow: 0 0 0 -1px #28ba62;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(1);
    box-shadow: 0 0 0 -1px #28ba62, 0 0 0 3px #fff, 0 0 15px -8px #28ba62;
  }
}

@keyframes moveCircle {
  100% {
    opacity: 0.3;
    transform: translateY(-50%) rotate(360deg);
  }
}
</style>
