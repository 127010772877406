import {
  REGISTRATION_ROUTE,
  REGISTRATION_PATH,
  REGISTRATION_WELCOME_ROUTE,
  REGISTRATION_WELCOME_PATH,
  REGISTRATION_SUCCESS_ROUTE,
  REGISTRATION_SUCCESS_PATH,
  DOX_READER_ROUTE,
  DOX_READER_PATH,
  DOX_PAIR_ROUTE,
  DOX_PAIR_PATH,
  REGISTRATION_ACTIVATION_PATH,
  REGISTRATION_ACTIVATION_ROUTE,
} from '@/constants/routes'

export default [
  {
    path: REGISTRATION_PATH,
    name: REGISTRATION_ROUTE,
    component: () => import(/* webpackChunkName: "registration" */ '@/views/Registration/Index.vue'),
    meta: {
      fullscreen: true,
    },
  },
  {
    path: REGISTRATION_WELCOME_PATH,
    name: REGISTRATION_WELCOME_ROUTE,
    component: () => import(/* webpackChunkName: "registration" */ '@/views/Registration/Welcome.vue'),
    meta: {
      unauthenticatedOnly: true,
      fullscreen: true,
    },
  },
  {
    path: REGISTRATION_SUCCESS_PATH,
    name: REGISTRATION_SUCCESS_ROUTE,
    component: () => import(/* webpackChunkName: "registration" */ '@/views/Registration/Success.vue'),
    meta: {
      fullscreen: true,
    },
  },
  {
    path: DOX_READER_PATH,
    name: DOX_READER_ROUTE,
    component: () => import(/* webpackChunkName: "registration" */ '@/views/Registration/DoxReader.vue'),
    meta: {
      fullscreen: true,
    },
  },
  {
    path: DOX_PAIR_PATH,
    name: DOX_PAIR_ROUTE,
    component: () => import(/* webpackChunkName: "registration" */ '@/views/Registration/DoxPair.vue'),
    meta: {
      authenticatedOnly: true,
      fullscreen: true,
    },
  },
  {
    path: REGISTRATION_ACTIVATION_PATH,
    name: REGISTRATION_ACTIVATION_ROUTE,
    component: () => import(/* webpackChunkName: "registration" */ '@/views/PasswordReset/PasswordResetNew.vue'),
    meta: {
      fullscreen: true,
    },
  },
]
