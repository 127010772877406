import { computed } from 'vue'
import { useStore } from 'vuex'

import { TOGGLE_PET_MENU } from '@/constants/types'
import { menuController } from '@ionic/vue'

export default function useApp() {
  const store = useStore()

  const APP_STATE = store.state.app
  const isPetMenuOpened = computed(() => store.getters.isPetMenuOpened)

  async function togglePetMenu(toggle) {
    try {
      await store.commit(TOGGLE_PET_MENU, toggle)
      await menuController.close()

      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  return {
    APP_STATE,
    isPetMenuOpened,
    togglePetMenu,
  }
}
