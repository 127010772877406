<template>
  <ion-icon :icon="require('@/assets/images/icons/cake.svg')" class="notification-img" />
  <div class="notification-content">
    <ion-text class="notification-title">
      Boldog születésnapot <strong>{{ notification.target_pet?.name ?? activePet.name }}</strong
      >!
    </ion-text>
    <time class="notification-date">{{ getNotificationDate(notification) }}</time>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { IonIcon, IonText } from '@ionic/vue'

import usePets from '@/composables/pets'
import useNotifications from '@/composables/notifications'

export default defineComponent({
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  components: {
    IonIcon,
    IonText,
  },
  setup() {
    const { activePet } = usePets()
    const { getNotificationDate } = useNotifications()

    return {
      activePet,
      getNotificationDate,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '../../theme/components/notifications';

.notification-img {
  height: 40px;
  margin-top: -10px;
}
</style>
