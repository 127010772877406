import { provide, inject, reactive } from 'vue'

const REGISTRATION_SYMBOL = Symbol('registration')

const createRegistrationState = () => {
  const state = {
    pet: {},
    dox: '',
    user: {},
    os: '',
    latitude: '',
    longtitude: '',
    city: '',
    country: '',
    address: '',
  }

  return reactive(state)
}

export const provideRegistration = () => provide(REGISTRATION_SYMBOL, createRegistrationState())
export const useRegistrationState = () => inject(REGISTRATION_SYMBOL)
