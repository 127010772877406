<template>
  <ion-avatar class="notification-img">
    <ion-img :src="notification.pet.avatar" :alt="notification.pet.name"></ion-img>
  </ion-avatar>
  <div class="notification-content">
    <ion-text class="notification-title">
      <strong>{{ notification.pet.name }}</strong> követni kezdte
      <strong>{{ notification.target_pet.name }}</strong> kedvenced.
    </ion-text>
    <time class="notification-date">{{ getNotificationDate(notification) }}</time>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { IonAvatar, IonImg, IonText } from '@ionic/vue'
import useNotifications from '@/composables/notifications'

export default defineComponent({
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  components: {
    IonAvatar,
    IonImg,
    IonText,
  },
  setup() {
    const { getNotificationDate } = useNotifications()

    return {
      getNotificationDate,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '../../theme/components/notifications';
</style>
