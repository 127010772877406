<template>
  <IonApp>
    <SplashScreen v-if="isLoading && isNative" />
    <Cookie v-if="isDesktop" />
    <AppNavigation v-if="!isLoading" />
    <DownloadApp v-if="!isLoading && !isNative" />
    <ion-router-outlet id="main" v-if="!isLoading" />
  </IonApp>
</template>

<script>
import moment from 'moment'
import { App } from '@capacitor/app'
import { Preferences as Storage } from '@capacitor/preferences'
import { Capacitor } from '@capacitor/core'
import { defineComponent, onBeforeMount, ref, onMounted, watch, computed } from 'vue'
import { IonApp, IonRouterOutlet, isPlatform } from '@ionic/vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import OneSignal from 'onesignal-cordova-plugin'

import httpClient from '@/core/api/api.service'
import { provideRegistration } from '@/store/registration'
import useStorage from '@/composables/storage'
import useNavigation from '@/composables/navigation'
import usePetFinder from '@/composables/petFinder'
import useAuth from '@/composables/auth'
import usePets from '@/composables/pets'
import useToast from '@/composables/toast'

import {
  SET_AUTH_USER,
  SET_PETS,
  SET_ACTIVE_PET,
  SET_NOTIFICATIONS,
  SET_AUTH_TOKEN,
  AUTH_LOGOUT,
  SET_NOTIFICATIONS_READ_AT,
  SET_PETS_WALKING,
  // SET_ACTIVE_WALKING,
} from '@/constants/types'
import useRegistration from '@/composables/registration'
import useGeolocation from '@/composables/geolocation'
import { DOX_READER_ROUTE } from '@/constants/routes'

import AppNavigation from '@/components/Navigation/AppNavigation'
import DownloadApp from '@/components/Navigation/DownloadApp'
import Cookie from '@/components/Global/Cookie'
import SplashScreen from '@/components/Global/SplashScreen'

import { Adjust, AdjustConfig, AdjustEnvironment, AdjustLogLevel } from '@awesome-cordova-plugins/adjust/'

const { getStorage, clearStorage } = useStorage()

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    AppNavigation,
    DownloadApp,
    Cookie,
    SplashScreen,
  },
  setup() {
    const route = useRoute()
    const store = useStore()
    const { navigateTo } = useNavigation()
    const { isServiceRunning, startService, stopService } = usePetFinder()
    const { isAuthenticated, isActiveAuthSession, activateAuthSession } = useAuth()
    const { PETS_STATE } = usePets()
    const { forceCreatePet } = useRegistration()
    const { askToTurnOnLocationServices, checkLocationServiceRequirements } = useGeolocation()

    const { openToast } = useToast()

    const isLoading = ref(true)
    const navigateData = ref(null)
    const hasPets = computed(() => PETS_STATE.pets && PETS_STATE.pets.length)
    const isDesktop = computed(() => isPlatform('desktop') && window.matchMedia('(min-width: 992px)').matches)
    const isNative = computed(() => Capacitor.isNativePlatform())

    provideRegistration()

    async function prepareStore() {
      await Storage.migrate()

      try {
        isLoading.value = true

        const authToken = await getStorage('auth_token')
        const authTokenExpiry = await getStorage('auth_token_expiry')
        const authUser = await getStorage('auth_user')
        const pets = await getStorage('pets')
        const activePetId = await getStorage('activePetId')
        const notifications = await getStorage('notifications')
        const notificationsReadAt = await getStorage('notificationsReadDate')
        const petsWalking = await getStorage('petsWalking')

        if (authToken) {
          httpClient.setAuthorizationHeader(authToken)
        }

        if (authUser?.id && isNative.value) {
          OneSignal.setExternalUserId(authUser.id.toString())
          OneSignal.sendTag('name', authUser.first_name)
        }

        /*if (await getStorage('activeWalking')) {
          await stopWalking()
        }*/

        setTimeout(
          async () => {
            isLoading.value = false
            if (isNative.value && authUser) {
              try {
                await startService()
              } catch (error) {
                await openToast({
                  message: error.message,
                  color: 'danger',
                })
              }
            } else {
              try {
                await checkLocationServiceRequirements()
                await askToTurnOnLocationServices()
              } catch {
                console.log('Could not ask to turn on location services')
              }
            }

            const config = new AdjustConfig('lenxokcqp4ao', AdjustEnvironment.Production)
            config.setLogLevel(AdjustLogLevel.Verbose)
            Adjust.create(config)
          },
          isNative.value ? 5000 : 0,
        )

        store.commit(SET_AUTH_TOKEN, { authToken, authTokenExpiry })
        store.commit(SET_AUTH_USER, authUser)
        store.commit(SET_PETS, pets)
        store.commit(SET_ACTIVE_PET, activePetId)
        store.commit(SET_NOTIFICATIONS, notifications)
        store.commit(SET_NOTIFICATIONS_READ_AT, notificationsReadAt || moment('1970.01.01').format())

        if (petsWalking && petsWalking.length) {
          petsWalking.forEach(petId => {
            store.commit(SET_PETS_WALKING, { petId, isWalking: true })
          })
        }

        if (authToken && authUser) {
          try {
            await activateAuthSession()
          } catch {}
        }

        // store.commit(SET_ACTIVE_WALKING, activeWalking || false)
      } catch (error) {
        if (error.message) {
          await openToast({
            message: error.message,
            color: 'warning',
          })
        }

        await clearStorage()
        isLoading.value = false
        store.commit(AUTH_LOGOUT)
        throw new Error('Error while setting up store')
      }
    }

    const OneSignalInit = () => {
      if (!isNative.value) return

      OneSignal.setAppId('375c579d-41f4-40d0-87ca-3654e125d356')
      OneSignal.setNotificationOpenedHandler(jsonData => {
        console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData))
      })

      OneSignal.promptForPushNotificationsWithUserResponse(async response => {
        console.log('User accepted notifications: ', response)

        if (response) {
          try {
            const authUser = await getStorage('auth_user')

            if (authUser?.id && isNative.value) {
              OneSignal.setExternalUserId(authUser.id.toString())
              OneSignal.sendTag('name', authUser.first_name)
            }
          } catch {
            console.log('Could not set external user id')
          }
        }
      })
    }

    watch(
      () => route.name,
      () => {
        if (isActiveAuthSession.value && isAuthenticated.value && !hasPets.value) {
          forceCreatePet()
        }
      },
    )

    onBeforeMount(async () => {
      await prepareStore()
      await OneSignalInit()

      if (isActiveAuthSession.value && isAuthenticated.value && !hasPets.value) {
        forceCreatePet()
      }
    })

    function getRFIDFromUrl(url) {
      const slug = url.split(process.env.VUE_APP_URL).pop()
      const rfid = slug.substring(slug.lastIndexOf('/') + 1)
      const oldUrls = [
        {
          url: '/beta/dante/',
          rfid: 'Green-HU11195',
        },
        {
          url: '/beta/rozi/',
          rfid: 'ROOKIE-HU11821',
        },
        {
          url: '/beta/reynard/',
          rfid: 'ROOKIE-HU11825',
        },
      ]

      const oldUrl = oldUrls.find(item => {
        const regex = new RegExp(`^${item.url}$`)
        return regex.test(slug)
      })

      if (oldUrl) {
        return oldUrl.rfid
      } else {
        return rfid
      }
    }

    function isDoxReaderUrl(url) {
      const urlRegexList = [
        '^/beta/dante/$',
        '^/beta/rozi/$',
        '^/beta/reynard/$',
        '^(/woof)(.*)$',
        '^(/beta/woof/uk)(.*)$',
        '^(/beta/woof/hu)(.*)$',
        '^(/doxocial-beta)(.*)$',
        '^(/woof/Alpha)(.*)$',
        '^(/woof/alpha)(.*)$',
      ]

      return urlRegexList.find(item => {
        const regex = new RegExp(item)
        return regex.test(url)
      })
    }

    App.addListener('appUrlOpen', function (data) {
      const slug = data.url.split(process.env.VUE_APP_URL).pop()

      if (!slug) {
        return
      }

      if (isDoxReaderUrl(slug)) {
        const rfid = getRFIDFromUrl(slug)

        navigateData.value = {
          name: DOX_READER_ROUTE,
          params: {
            hash: rfid,
          },
        }
      } else {
        navigateData.value = {
          path: slug,
        }
      }
    })

    App.addListener('appStateChange', state => {
      if (!state.isActive) {
        isServiceRunning.value && stopService()
      }
    })

    watch(
      () => navigateData.value,
      async () => {
        if (navigateData.value) {
          setTimeout(() => {
            navigateTo(navigateData.value)
            navigateData.value = null
          }, 2000)
        }
      },
    )

    return {
      isLoading,
      isDesktop,
      isNative,
    }
  },
})
</script>

<style lang="scss">
@import 'theme/app';
</style>
