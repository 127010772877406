<template>
  <ion-menu class="menu" side="end" content-id="main" menu-id="notification-menu" :swipe-gesture="hasSwipe">
    <ion-content>
      <ion-list class="menu-list ion-no-padding">
        <div class="item">
          <template
            v-if="
              newestNotifications?.length > 0 || (newestNotifications?.length == 0 && oldestNotifications?.length === 0)
            "
          >
            <h2 class="small">Új értesítések</h2>
            <div class="no-notification" v-if="newestNotifications?.length === 0">
              <ion-avatar class="dox-logo">
                <ion-img :src="require('@/assets/images/dox-logo.jpg')" alt="DOX icon" />
              </ion-avatar>
              <div>
                <p>Nem érkezett új értesítésed.</p>
                Tölts föl egy képet és kövess másokat.
              </div>
            </div>
          </template>

          <div v-if="newestNotifications?.length === 0 && oldestNotifications?.length === 0" class="cta-action">
            <div class="pet-container" @click="goCreatePost">
              <ion-img :src="require(`@/assets/images/animated/dog-head.gif`)" alt="pet" class="pet-icon" />
              <ion-img
                :src="require('@/assets/images/icons/circle-plus-black.svg')"
                alt="Hozzáadás"
                class="pet-plus-icon"
              />
            </div>
            <h1>Itt az ideje egy új kalandnak!</h1>
            <p>
              Ahogy Neked, úgy a kedvencednek is szüksége van barátokra és kalandokra. Tölts föl egy képet, vagy
              induljatok közös sétákra!
            </p>
          </div>
          <NotificationItem
            v-for="notification in newestNotifications"
            :key="notification.id"
            :notification="notification"
          />
        </div>
        <div v-if="oldestNotifications.length" class="item">
          <h2 class="small">Korábbi értesítések</h2>
          <NotificationItem
            v-for="notification in oldestNotifications"
            :key="notification.id"
            :notification="notification"
          />
        </div>
        <ion-item class="menu-item menu-item--buy-dox" lines="none">
          <ion-button
            expand="block"
            shape="round"
            strong="true"
            @click="openBrowserLink('https://dox-tag.eu')"
            class="btn btn-dox"
          >
            Vásárolj egy doxot
            <ion-ripple-effect></ion-ripple-effect>
          </ion-button>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-menu>
</template>

<script>
import { defineComponent, computed, onMounted, watch, ref } from 'vue'
import {
  IonMenu,
  IonContent,
  IonButton,
  IonList,
  IonRippleEffect,
  IonItem,
  IonImg,
  isPlatform,
  menuController,
} from '@ionic/vue'
import router from '@/router'

import { PET_WALKING_ROUTE, POST_CREATE_ROUTE } from '@/constants/routes'
import useNotifications from '@/composables/notifications'
import useNavigation from '@/composables/navigation'

import NotificationItem from '@/components/Notification/NotificationItem'

export default defineComponent({
  name: 'NotificationsMenu',
  components: {
    IonMenu,
    IonContent,
    IonButton,
    IonList,
    IonItem,
    IonRippleEffect,
    IonImg,
    NotificationItem,
  },
  setup() {
    const { NOTIFICATION_STATE, filterNotificationsByDay, isLoading, fetchNotifications } = useNotifications()
    const { navigateTo } = useNavigation()

    onMounted(async () => {
      await fetchNotifications()
      await setNotifications()
    })

    const isCurrentRoute = route => route === router.currentRoute.value.name
    const hasSwipe = computed(() => {
      return !isPlatform('desktop') && !isCurrentRoute(PET_WALKING_ROUTE)
    })

    const goCreatePost = async () => {
      await menuController.close()
      await navigateTo({ name: POST_CREATE_ROUTE })
    }

    const notifications = computed(() => NOTIFICATION_STATE.notifications ?? [])
    const newestNotifications = ref([])
    const oldestNotifications = ref([])

    const setNotifications = () => {
      newestNotifications.value = filterNotificationsByDay(notifications.value, {
        minDay: 0,
        maxDay: 3,
      }).sort((a, b) => new Date(b.date) - new Date(a.date))

      oldestNotifications.value = filterNotificationsByDay(notifications.value, {
        minDay: 3,
        maxDay: 30,
      }).sort((a, b) => new Date(b.date) - new Date(a.date))
    }

    watch(() => notifications.value, setNotifications)

    return {
      newestNotifications,
      oldestNotifications,
      isLoading,
      hasSwipe,
      goCreatePost,
    }
  },
})
</script>

<style lang="scss" scoped>
ion-button {
  --background: #e6224a;
  --box-shadow: none;
}

.menu-item--buy-dox {
  display: none;
}
.menu {
  z-index: 9;
  border-top: none;
  color: var(--ion-color-dark);

  &:not(&:has(.ios)) ion-content {
    --padding-top: 40px;
  }

  .item {
    padding: 0px 20px 20px 20px;
  }

  .menu-list {
    padding-bottom: calc(env(safe-area-inset-bottom) + 50px + 110px);
  }
}

.no-notification {
  display: flex;
  align-items: center;
  background-color: #fff;
  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 50px;
  font-size: 10px;
  line-height: 1;

  .dox-logo {
    min-width: 50px;
    max-width: 50px;
    max-height: 50px;
    margin-right: 15px;
  }

  p {
    font-size: 14px;
    font-weight: bold;
    line-height: 1;
    margin-top: 0;
    margin-bottom: 8px;
  }
}

.cta-action {
  margin-top: 131px;
  text-align: center;

  padding: 0 20%;

  h1 {
    font-size: 12px !important;
    font-weight: 900;
  }
}

.pet-container {
  position: relative;
  cursor: pointer;

  .pet-icon {
    max-width: 93px;
    margin: 0 auto;
  }

  .pet-plus-icon {
    position: absolute;
    max-width: 32px;
    min-width: 32px;
    border: 4px #fff solid;
    right: 50%;
    bottom: -5px;
    transform: translateX(45px);
    border-radius: 50%;
  }
}
</style>
