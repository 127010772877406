import { computed, ref, reactive } from 'vue'
import { useStore } from 'vuex'

import helpers from '@/utils/helpers'
import httpClient from '@/core/api/api.service'
import useStorage from '@/composables/storage'
import usePosts from '@/composables/posts'

import {
  SET_ACTIVE_PET,
  SET_PET_OPTIONS,
  UPDATE_PET,
  DELETE_PET,
  SET_PETS_WALKING,
  SET_ACTIVE_WALKING,
} from '@/constants/types'

const petFeed = reactive({
  posts: [],
  hasNextPage: true,
  nextPage: 1,
  isLoading: false,
})

const pet = ref(null)
const petsInMap = ref([])

export default function usePets() {
  const store = useStore()
  const unavailable = ref(false)
  const { setStorage } = useStorage()
  const { goToFeed, resetFeed } = usePosts()

  const COUNTRIES = ref(null)
  const PETS_STATE = store.state.pet
  const isMyPet = pet => PETS_STATE.pets && PETS_STATE.pets.find(item => item.id === pet.id)

  const setActivePet = async ({ id }) => {
    try {
      setStorage('activePetId', id)
      store.commit(SET_ACTIVE_PET, id)
      await httpClient.patch('me', '', { active_pet_id: id })
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const updatePet = async (pet, params) => {
    try {
      const { data } = await httpClient.patch('pets', pet.id, params)

      store.commit(UPDATE_PET, { petId: pet.id, petData: data.data })

      return Promise.resolve(data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const deletePet = async petId => {
    try {
      await httpClient.remove('pets', petId)
      store.commit(DELETE_PET, petId)

      // todo if store petWalking contains id then delete
      // setPetWalking({ has_dox: false, id: petId }, false)

      if (PETS_STATE.pets.length) {
        await setActivePet({ id: PETS_STATE.pets[0].id })
        resetFeed()
      }

      goToFeed()
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const fetchPet = async id => {
    if (pet.value) {
      pet.value = null
    }

    try {
      const { data } = await httpClient.get('pets', id)

      const petId = data.data.id
      const myPet = PETS_STATE.pets && PETS_STATE.pets.find(item => item.id === petId)

      if (myPet) {
        store.commit(UPDATE_PET, { petId: data.data.id, petData: data.data })
      }

      pet.value = data.data

      return Promise.resolve(data.data)
    } catch (error) {
      unavailable.value = true
      return Promise.reject(error)
    }
  }

  const fetchCountries = async () => {
    try {
      const { data } = await httpClient.get('countries')
      const options = data.data

      COUNTRIES.value = options
      return Promise.resolve(options)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const fetchPetOptions = async species_id => {
    try {
      const { data } = await httpClient.get('options', '', { species_id })
      const options = data.data

      store.commit(SET_PET_OPTIONS, options)
      return Promise.resolve(options)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const blockPet = async pet_id => {
    try {
      await httpClient.save(`pets/${pet_id}/block`)

      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  function updatePetPosts(data) {
    petFeed.posts = helpers.appendToList(petFeed.posts, data)
  }

  function setNextPage(currentPage) {
    petFeed.nextPage = currentPage + 1
  }

  function toggleHasNextPage() {
    petFeed.hasNextPage = !petFeed.hasNextPage
  }

  async function fetchPetPosts(petId) {
    if (petFeed.isLoading) return

    try {
      petFeed.isLoading = true

      const params = {
        page: petFeed.nextPage,
        limit: 10,
        pet_id: petId,
      }

      const { data } = await httpClient.get('posts', '', params)

      const posts = data.data
      const pages = data.pagination

      if (petFeed.nextPage > 1) {
        updatePetPosts(posts)
      } else {
        petFeed.posts = posts
      }

      if (pages.lastPage > pages.currentPage) {
        setNextPage(pages.currentPage)
      } else {
        toggleHasNextPage()
      }

      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    } finally {
      petFeed.isLoading = false
    }
  }

  async function loadPetPosts(event, petId) {
    await fetchPetPosts(petId)
    event.target.complete()
  }

  function resetPetPosts() {
    petFeed.posts = []
    petFeed.hasNextPage = true
    petFeed.nextPage = 1
  }

  async function setPetWalking(pet, isWalking) {
    store.commit(SET_PETS_WALKING, { petId: pet.id, isWalking })
  }

  const refreshWalking = async (coords, testData) => {
    try {
      if (!testData) {
        // console.log('position input in refreshdata', coords.latitude, coords.longitude)
        const result = await httpClient.save(`walk/update`, {
          pets: PETS_STATE.petsWalking,
          latitude: coords.latitude,
          longitude: coords.longitude,
        })
        return Promise.resolve(result)
      } else {
        return Promise.resolve(testData)
      }
    } catch (error) {
      // todo: error handling
      return Promise.reject(error)
    }
  }

  const startWalking = async (coords, testData) => {
    try {
      if (!testData) {
        const { data } = await httpClient.save(`walk`, {
          pets: PETS_STATE.petsWalking,
          latitude: coords.latitude,
          longitude: coords.longitude,
        })
        store.commit(SET_ACTIVE_WALKING, true)
        return Promise.resolve(data)
      } else {
        store.commit(SET_ACTIVE_WALKING, true)
        return Promise.resolve(testData)
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const getCurrentLeaderboard = async () => {
    try {
      const { data } = await httpClient.get(`leaderboard`)

      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const getActivePetPoints = async () => {
    try {
      const { data } = await httpClient.get(`leaderboard/getactivepoints`)

      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const givePointToPet = async (pet_id, reason) => {
    try {
      const { data } = await httpClient.save(`leaderboard/givepoint`, {
        pet_id,
        reason,
      })

      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const stopWalking = async testData => {
    try {
      if (!testData) {
        const { data } = await httpClient.remove(`walk`)
        if (data.success) {
          store.commit(SET_ACTIVE_WALKING, false)
        } else {
          return Promise.reject('Stop walking error')
        }
        return Promise.resolve(data)
      } else {
        store.commit(SET_ACTIVE_WALKING, false)
        return Promise.resolve({ success: true })
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const getActiveWalking = async testData => {
    try {
      if (!testData) {
        const { data } = await httpClient.get(`walk`)
        store.commit(SET_ACTIVE_WALKING, data.walking)
        return Promise.resolve(data)
      } else {
        // store.commit(SET_ACTIVE_WALKING, false)
        return Promise.resolve(null)
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const getMessages = async () => {
    try {
      const { data } = await httpClient.get(`messages`)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const getMessagesByPet = async (targetPet, myPet) => {
    try {
      const { data } = await httpClient.get(`messages/${targetPet}/${myPet}`)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const sendMessageToPet = async (targetPet, myPet, message) => {
    try {
      const { data } = await httpClient.save(`messages/${targetPet}/${myPet}/send`, {
        message,
      })
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const setPetsInMap = petsData => {
    petsInMap.value = petsData
  }

  return {
    PETS_STATE,
    activePet: computed(() => store.getters.activePet),
    petsWithActivePetFirst: computed(() => store.getters.petsWithActivePetFirst),
    setActivePet,
    isMyPet,
    updatePet,
    fetchPet,
    fetchPetOptions,
    fetchCountries,
    COUNTRIES,
    blockPet,
    petFeed,
    loadPetPosts,
    fetchPetPosts,
    resetPetPosts,
    pet,
    unavailable,
    deletePet,
    setPetWalking,
    refreshWalking,
    startWalking,
    stopWalking,
    getActiveWalking,
    petsInMap,
    setPetsInMap,
    getCurrentLeaderboard,
    givePointToPet,
    getActivePetPoints,
    getMessages,
    getMessagesByPet,
    sendMessageToPet,
  }
}
