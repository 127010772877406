<template>
  <div class="floating-menu" v-if="activePet && !floatingButtonsDisabled">
    <div class="floating-menu__toggle" :class="{ 'is-open': isMenuOpened }" @click="toggleMenu">
      <ion-img
        :src="require('@/assets/images/icons/close.svg')"
        alt="Lebegő menü"
        v-if="floatingMenuIcon === 'close'"
      />
      <lottie-animation
        ref="anim"
        :playInterval="20000"
        :animationData="require('@/assets/images/lottie/floating/floating_dog.json')"
        v-if="floatingMenuIcon === 'dog'"
      />
      <lottie-animation
        ref="anim"
        :playInterval="20000"
        :animationData="require('@/assets/images/lottie/floating/floating-cat.json')"
        v-if="floatingMenuIcon === 'cat'"
      />
    </div>

    <div
      class="floating-menu__item only-lg"
      :class="{ 'router-link-active': isCurrentRoute(FEED_ROUTE), 'is-open': isMenuOpened }"
      @click.prevent="goHome"
    >
      <ion-img
        :src="require('@/assets/images/icons/home-gray.svg')"
        alt="Hírfolyam"
        class="floating-menu__item__icon"
      />
      <ion-text class="floating-menu__item__label">Hírfolyam</ion-text>
    </div>
    <div
      class="floating-menu__item only-lg"
      :class="{ 'router-link-active': isCurrentRoute(DOX_DATA_ROUTE), 'is-open': isMenuOpened }"
      @click.prevent="goDoxData"
    >
      <div class="floating-menu__item__wrapper">
        <ion-img
          :src="require('@/assets/images/icons/dox-gray.svg')"
          alt="Üzeneteim"
          class="floating-menu__item__icon"
        />
      </div>
      <ion-text class="floating-menu__item__label">DOX Menü</ion-text>
    </div>
    <div
      class="floating-menu__item only-lg"
      :class="{ 'router-link-active': isCurrentRoute(PET_SEARCH_ROUTE), 'is-open': isMenuOpened }"
      @click.prevent="goSearch"
    >
      <ion-img
        :src="require('@/assets/images/icons/search-gray.svg')"
        alt="Keresés"
        class="floating-menu__item__icon"
      />
      <ion-text class="floating-menu__item__label">Keresés</ion-text>
    </div>

    <div
      class="floating-menu__item"
      :class="{ 'router-link-active': isCurrentRoute(AI_ROUTE), 'is-open': isMenuOpened }"
      @click.prevent="goToAI"
    >
      <lottie-animation
        ref="anim"
        :loop="true"
        :animationData="require('@/assets/images/lottie/floating/ai-logo.json')"
        class="floating-menu__item__icon"
      />
      <ion-text class="floating-menu__item__label">AI</ion-text>
    </div>
    <div
      class="floating-menu__item"
      :class="{
        'router-link-active': isCurrentRoute(PAGE_MESSAGES_ROUTE) || isCurrentRoute(PAGE_CHAT_ROUTE),
        'is-open': isMenuOpened,
      }"
      @click.prevent="goMessages"
    >
      <ion-img
        :src="require('@/assets/images/icons/message-gray.svg')"
        alt="Üzeneteim"
        class="floating-menu__item__icon"
      />
      <ion-text class="floating-menu__item__label">Üzeneteim</ion-text>
    </div>
    <div
      class="floating-menu__item"
      :class="{ 'router-link-active': isCurrentRoute(POST_CREATE_ROUTE), 'is-open': isMenuOpened }"
      @click.prevent="goCreatePost"
    >
      <ion-img
        :src="require('@/assets/images/icons/posticon.svg')"
        alt="Képfeltöltés"
        class="floating-menu__item__icon"
      />
      <ion-text class="floating-menu__item__label">Képfeltöltés</ion-text>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref } from 'vue'
import { IonImg, IonText, isPlatform } from '@ionic/vue'

import usePets from '@/composables/pets'
import useNavigation from '@/composables/navigation'
import usePosts from '@/composables/posts'

import {
  FEED_ROUTE,
  DOX_DATA_ROUTE,
  PET_SEARCH_ROUTE,
  PAGE_MESSAGES_ROUTE,
  PAGE_CHAT_ROUTE,
  POST_CREATE_ROUTE,
  AI_ROUTE,
} from '@/constants/routes'

import router from '@/router'
import { useRoute } from 'vue-router'

export default defineComponent({
  components: {
    IonImg,
    IonText,
  },
  setup() {
    const route = useRoute()
    const { activePet } = usePets()
    const { navigateTo } = useNavigation()
    const { goToFeed } = usePosts()

    const isMenuOpened = ref(false)

    const floatingButtonsDisabled = computed(() => route.meta.floatingButtonsDisabled)

    const toggleMenu = () => {
      if (isPlatform('desktop') && window.matchMedia('(min-width: 1025px)').matches) {
        return
      }

      isMenuOpened.value = !isMenuOpened.value
    }

    const floatingMenuIcon = computed(() => {
      const pet = activePet.value.species.id === 1 ? 'dog' : 'cat'
      return isMenuOpened.value ? 'close' : pet
    })

    const goHome = async () => {
      goToFeed()
      toggleMenu()
    }

    const goDoxData = () => {
      navigateTo({ name: DOX_DATA_ROUTE })
      toggleMenu()
    }

    const goCreatePost = () => {
      navigateTo({ name: POST_CREATE_ROUTE })
      toggleMenu()
    }

    const goSearch = () => {
      navigateTo({ name: PET_SEARCH_ROUTE })
      toggleMenu()
    }

    const goMessages = () => {
      navigateTo({ name: PAGE_MESSAGES_ROUTE })
      toggleMenu()
    }

    const goToAI = () => {
      navigateTo({ name: AI_ROUTE })
      toggleMenu()
    }

    const isCurrentRoute = route => route === router.currentRoute.value.name

    return {
      toggleMenu,
      floatingMenuIcon,
      goHome,
      goDoxData,
      goCreatePost,
      goSearch,
      goMessages,
      goToAI,
      activePet,
      FEED_ROUTE,
      DOX_DATA_ROUTE,
      PET_SEARCH_ROUTE,
      PAGE_MESSAGES_ROUTE,
      PAGE_CHAT_ROUTE,
      POST_CREATE_ROUTE,
      AI_ROUTE,
      isCurrentRoute,
      floatingButtonsDisabled,
      isMenuOpened,
    }
  },
})
</script>

<style lang="scss" scoped>
.lottie-animation {
  height: 61px;
}
.floating-menu {
  position: fixed;
  z-index: 8;

  @media (max-width: 767px) {
    right: 1rem;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    right: 2rem;
  }

  @media (max-width: 1024px) {
    bottom: calc(70px + env(safe-area-inset-bottom));
  }

  @media (min-width: 1025px) {
    top: 120px;
    z-index: 8;
  }

  @media (min-width: 1025px) and (max-width: 1199px) {
    left: 1rem;
  }

  @media (min-width: 1200px) {
    left: calc((100vw - 1140px + 2rem) / 2);
  }

  &__item,
  &__toggle {
    cursor: pointer;
    height: 61px;
    border-radius: 61px;
    display: flex;
    align-items: center;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);

    @media (max-width: 1024px) {
      position: absolute;
      right: 0;
      width: 61px;
      justify-content: center;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
    }
  }

  &__item {
    background: #fff;
    bottom: 0;
    transition: all 400ms cubic-bezier(0.23, 1, 0.32, 1);

    ion-img {
      height: 24px;
    }

    &__wrapper {
      width: 24px;
      margin-right: 13px;
    }

    &:nth-child(3) ion-img {
      margin: 0 auto;
    }

    &:nth-child(6) ion-img {
      position: relative;
      &::after {
        position: absolute;
        content: '1';
        width: 13px;
        height: 13px;
        background: #e6224a;
        border-radius: 50%;
        color: white;
        font-size: 9px;
        font-weight: 800;
        text-align: center;
        line-height: 14px;
        bottom: -5px;
        right: -5px;
        border: 1.5px solid white;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    @media (max-width: 1024px) {
      opacity: 0;

      &:nth-child(5) {
        z-index: 8;
        bottom: 76px;
        transform: translateY(76px);

        ion-img {
          height: 34px;
        }
      }

      &:nth-child(6) {
        z-index: 8;
        bottom: 152px;
        transform: translateY(152px);

        ion-img {
          height: 29px;
        }
      }

      &:nth-child(7) {
        z-index: 7;
        bottom: 228px;
        transform: translateY(228px);

        ion-img {
          position: relative;
          top: 2px;
          height: 33px;
        }
      }

      &.is-open {
        opacity: 1;
        transform: translate(0);
      }
    }

    @media (min-width: 1025px) {
      padding: 0 20px;
      margin-bottom: 10px;
      box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.13);
      transition: 0.2s ease-out;
    }

    @media (min-width: 1025px) and (max-width: 1199px) {
      width: 180px;
    }

    @media (min-width: 1200px) {
      width: 200px;
    }

    &__label {
      font-weight: var(--font-weight-bold);
      @media (max-width: 1024px) {
        display: none;
      }

      @media (min-width: 1025px) {
        text-transform: uppercase;
        font-family: var(--ion-poppins-font);
        font-size: 12px;
        letter-spacing: 0.03571em;
      }
    }

    &__icon {
      @media (min-width: 1025px) {
        margin-right: 13px;
      }
    }

    &.router-link-active {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.13);
      background: #e1e1e1;
    }
  }

  &__toggle {
    @media (max-width: 1024px) {
      background: #111;
      z-index: 9;
      bottom: 0;

      ion-img {
        width: 40px;
      }

      &.is-open {
        ion-img {
          width: 20px;
        }
      }
    }

    @media (min-width: 1025px) {
      display: none;
    }
  }
}
.only-lg {
  display: none;
}
@media only screen and (min-width: 1024px) {
  .only-lg {
    display: flex;
  }
}
</style>
