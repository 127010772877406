import useStorage from '@/composables/storage'
import {
  SET_PETS,
  SET_ACTIVE_PET,
  SET_ACTIVE_PET_STATUS,
  SET_PET_OPTIONS,
  UPDATE_PET,
  DELETE_PET,
  SET_PETS_WALKING,
  SET_ACTIVE_WALKING,
} from '@/constants/types'
import PETS_STATUS from '@/constants/pet-status'

const { setStorage } = useStorage()

const state = {
  pets: null,
  activePetId: null,
  options: null,
  petsWalking: [],
  activeWalking: null,
}

const getters = {
  activePet: state => state.pets?.find(pet => pet.id === state.activePetId),
  petsWithActivePetFirst: state => {
    const activePet = state.pets?.find(pet => pet.id === state.activePetId)
    const otherPets = state.pets?.filter(pet => pet.id !== activePet?.id)

    return [activePet, ...otherPets]
  },
}

const actions = {}

const mutations = {
  [SET_PETS](state, data) {
    setStorage('pets', data)
    state.pets = data
  },
  [SET_ACTIVE_PET](state, id) {
    setStorage('activePetId', id)
    state.activePetId = id
  },
  [SET_ACTIVE_PET_STATUS](state) {
    const activePet = state.pets.find(item => item.id === state.activePetId)
    activePet.status = activePet.status === PETS_STATUS.MISSING ? PETS_STATUS.ACTIVE : PETS_STATUS.MISSING
  },
  [SET_PET_OPTIONS](state, data) {
    state.options = data
  },
  [UPDATE_PET](state, { petId, petData }) {
    Object.assign(
      state.pets.find(item => item.id === petId),
      petData,
    )
    setStorage('pets', state.pets)
  },
  [DELETE_PET](state, petId) {
    const index = state.pets.findIndex(pet => pet.id == petId)
    state.pets.splice(index, 1)
    setStorage('pets', state.pets)
  },
  [SET_PETS_WALKING](state, data) {
    // active pet mindig az elejére kerüljön
    const petId = data.petId
    const isWalking = data.isWalking // selected
    const index = state.petsWalking.findIndex(id => id == petId)
    if (isWalking && index == -1) {
      if (petId == state.activePetId) {
        // ha nem volt kiválasztva az aktív pet, akkor az első helyre kerül
        state.petsWalking.unshift(petId)
      } else {
        state.petsWalking.push(petId)
      }
    } else if (!isWalking && index > -1) {
      state.petsWalking.splice(index, 1)
    } else if (isWalking && index > 0 && petId == state.activePetId) {
      // ha aktív pet ki van jelölve, és nem az első helyen van, akkor áttesszük előre
      state.petsWalking.splice(index, 1)
      state.petsWalking.unshift(petId)
    }
    setStorage('petsWalking', state.petsWalking)
  },
  [SET_ACTIVE_WALKING](state, active) {
    setStorage('activeWalking', active)
    state.activeWalking = active
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
