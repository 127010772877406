import {
  PAGE_ABOUT_ROUTE,
  PAGE_ABOUT_PATH,
  PAGE_SETTINGS_ROUTE,
  PAGE_SETTINGS_PATH,
  PAGE_PRESENT_FOUND_ROUTE,
  PAGE_PRESENT_FOUND_PATH,
  PAGE_PRESENT_SCAN_ROUTE,
  PAGE_PRESENT_SCAN_PATH,
  PAGE_PRESENT_CAMERA_ROUTE,
  PAGE_PRESENT_CAMERA_PATH,
  PAGE_MESSAGES_ROUTE,
  PAGE_MESSAGES_PATH,
  PAGE_CHAT_ROUTE,
  PAGE_CHAT_PATH,
  USER_INVITE_ROUTE,
  USER_INVITE_PATH,
  INVITE_ACCEPT_ROUTE,
  INVITE_ACCEPT_PATH,
  PAGE_EVENT_CHAT_ROUTE,
  PAGE_EVENT_CHAT_PATH,
  PAGE_EVENT_EDIT_ROUTE,
  PAGE_EVENT_EDIT_PATH,
  PET_WALKING_PATH,
  EVENT_CALENDAR_ROUTE,
  EVENT_CALENDAR_PATH,
  POI_REDIRECT_ROUTE,
  POI_REDIRECT_PATH,
  EVENT_REDIRECT_ROUTE,
  EVENT_REDIRECT_PATH,
  AI_ROUTE,
  AI_PATH,
} from '@/constants/routes'

export default [
  {
    path: PAGE_ABOUT_PATH,
    name: PAGE_ABOUT_ROUTE,
    component: () => import(/* webpackChunkName: "pages" */ '@/views/Pages/About.vue'),
    meta: {
      canGoBack: true,
      floatingButtonsDisabled: true,
    },
  },
  {
    path: PAGE_SETTINGS_PATH,
    name: PAGE_SETTINGS_ROUTE,
    component: () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Settings.vue'),
    meta: {
      canGoBack: true,
      authenticatedOnly: true,
    },
  },
  {
    name: PAGE_PRESENT_FOUND_ROUTE,
    path: PAGE_PRESENT_FOUND_PATH,
    component: () => import(/* webpackChunkName: "pet" */ '@/views/Pois/Present.vue'),
    meta: {
      canGoBack: false,
      authenticatedOnly: false,
    },
  },
  {
    name: PAGE_PRESENT_SCAN_ROUTE,
    path: PAGE_PRESENT_SCAN_PATH,
    component: () => import(/* webpackChunkName: "pet" */ '@/views/Pois/PresentScan.vue'),
    meta: {
      canGoBack: false,
      authenticatedOnly: true,
    },
  },
  {
    name: PAGE_PRESENT_CAMERA_ROUTE,
    path: PAGE_PRESENT_CAMERA_PATH,
    component: () => import(/* webpackChunkName: "pet" */ '@/views/Pois/PresentCamera.vue'),
    meta: {
      canGoBack: true,
      authenticatedOnly: true,
    },
  },
  {
    path: PAGE_MESSAGES_PATH,
    name: PAGE_MESSAGES_ROUTE,
    component: () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Messages.vue'),
    meta: {
      canGoBack: false,
      authenticatedOnly: true,
    },
  },
  {
    path: PAGE_CHAT_PATH,
    name: PAGE_CHAT_ROUTE,
    component: () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Chat.vue'),
    meta: {
      canGoBack: true,
      authenticatedOnly: true,
    },
  },
  {
    path: USER_INVITE_PATH,
    name: USER_INVITE_ROUTE,
    component: () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Invite.vue'),
    meta: {
      canGoBack: true,
      authenticatedOnly: true,
    },
  },
  {
    path: INVITE_ACCEPT_PATH,
    name: INVITE_ACCEPT_ROUTE,
    component: () => import(/* webpackChunkName: "pages" */ '@/views/Pages/InviteAccept.vue'),
  },
  {
    name: PAGE_EVENT_CHAT_ROUTE,
    path: PAGE_EVENT_CHAT_PATH,
    component: () => import(/* webpackChunkName: "pages" */ '@/views/Pois/Event/Chat.vue'),
    meta: {
      goBackRoute: PET_WALKING_PATH,
      canGoBack: true,
      authenticatedOnly: true,
    },
  },
  {
    name: PAGE_EVENT_EDIT_ROUTE,
    path: PAGE_EVENT_EDIT_PATH,
    component: () => import(/* webpackChunkName: "pet" */ '@/views/Pois/EditEvent.vue'),
    meta: {
      canGoBack: true,
      authenticatedOnly: true,
    },
  },
  {
    name: EVENT_CALENDAR_ROUTE,
    path: EVENT_CALENDAR_PATH,
    component: () => import(/* webpackChunkName: "pet" */ '@/views/Pages/Event/Calendar.vue'),
    meta: {
      canGoBack: true,
      authenticatedOnly: true,
    },
  },
  {
    name: EVENT_REDIRECT_ROUTE,
    path: EVENT_REDIRECT_PATH,
    component: () => import(/* webpackChunkName: "pet" */ '@/views/Pages/MapRedirect.vue'),
    meta: {
      canGoBack: false,
      authenticatedOnly: false,
    },
  },
  {
    name: POI_REDIRECT_ROUTE,
    path: POI_REDIRECT_PATH,
    component: () => import(/* webpackChunkName: "pet" */ '@/views/Pages/MapRedirect.vue'),
    meta: {
      canGoBack: false,
      authenticatedOnly: false,
    },
  },
  {
    name: AI_ROUTE,
    path: AI_PATH,
    component: () => import(/* webpackChunkName: "pet" */ '@/views/Pages/AI.vue'),
    meta: {
      canGoBack: true,
      authenticatedOnly: true,
    },
  },
]
