import useStorage from '@/composables/storage'

const AUTH_TOKEN_KEY = 'auth_token'
const AUTH_TOKEN_EXPIRY_KEY = 'auth_token_expiry'

const { getStorage, setStorage, removeStorage } = useStorage()

const getAuthToken = () => getStorage(AUTH_TOKEN_KEY) || ''
const setAuthToken = token => setStorage(AUTH_TOKEN_KEY, token)
const removeAuthToken = () => removeStorage(AUTH_TOKEN_KEY)

const getAuthTokenExpiry = () => getStorage(AUTH_TOKEN_EXPIRY_KEY)
const setAuthTokenExpiry = expiry => setStorage(AUTH_TOKEN_EXPIRY_KEY, expiry)
const removeAuthTokenExpiry = () => removeStorage(AUTH_TOKEN_EXPIRY_KEY)

export { getAuthToken, setAuthToken, removeAuthToken, getAuthTokenExpiry, setAuthTokenExpiry, removeAuthTokenExpiry }
