<template>
  <ion-avatar @click.prevent.stop="goToProfile" class="notification-img">
    <ion-img :src="notification.pet.avatar" :alt="notification.pet.name"></ion-img>
  </ion-avatar>
  <div class="notification-content">
    <ion-text class="notification-title">
      <strong>{{ notification.pet.name }}</strong> rátappancsolt
      <strong>{{ notification.target_pet.name }}</strong> képére.
    </ion-text>
    <time class="notification-date">{{ getNotificationDate(notification) }}</time>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { IonAvatar, IonImg, IonText, menuController } from '@ionic/vue'

import useNotifications from '@/composables/notifications'
import useNavigation from '@/composables/navigation'

export default defineComponent({
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  components: {
    IonAvatar,
    IonImg,
    IonText,
  },
  setup(props) {
    const { getNotificationDate } = useNotifications()
    const { navigateTo } = useNavigation()

    async function goToProfile() {
      await menuController.close()
      navigateTo({ name: 'pet', params: { id: props.notification.pet.id } })
    }

    return {
      getNotificationDate,
      goToProfile,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '../../theme/components/notifications';
</style>
