import axios from 'axios'
import store from '@/store'
import router from '@/router'

import { LOGIN_ROUTE } from '@/constants/routes'
import { AUTH_LOGOUT } from '@/constants/types'
import useStorage from '@/composables/storage'

const { clearStorage } = useStorage()

const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  validateStatus(status) {
    return status >= 200 && status <= 303
  },
})

const setAuthorizationHeader = token => {
  if (!token) {
    return
  }

  api.defaults.headers.Authorization = `Bearer ${token}`
}

const collectionResponseHandler = async response => {
  return Promise.resolve({
    data: response.data,
    raw: response,
  })
}

const singularResponseHandler = async response => {
  return Promise.resolve({
    data: response.data,
    raw: response,
  })
}

const generalErrorHandler = async error => {
  console.log('generealError ', error)
  console.error(`[Doxocial] ApiService ${error}`)

  if (error.response.status === 401) {
    const { isAuthenticated } = store.getters

    if (isAuthenticated) {
      try {
        store.commit(AUTH_LOGOUT)

        clearStorage()

        await router.push({ name: LOGIN_ROUTE })

        return Promise.resolve()
      } catch (err) {
        return Promise.reject(err)
      }
    } else {
      await router.push({ name: LOGIN_ROUTE })
      return Promise.reject(error)
    }
  }

  return Promise.reject({
    errStatus: error.response.status,
    errData: error.response.data,
    errRaw: error,
  })
}

const request = (method, resource, params, responseType) => {
  return api
    .request({
      method,
      url: `${resource}`,
      params,
      responseType,
    })
    .catch(generalErrorHandler)
}

const get = (resource, slug = '', params) => {
  return api
    .request({
      method: 'get',
      url: slug ? `${resource}/${slug}` : resource,
      params,
    })
    .then(singularResponseHandler)
    .catch(generalErrorHandler)
}

const query = (resource, params) => {
  return api
    .request({
      method: 'get',
      url: resource,
      params,
    })
    .then(collectionResponseHandler)
    .catch(generalErrorHandler)
}

const save = (resource, data, params) => {
  return api
    .request({
      method: 'post',
      url: `${resource}`,
      data,
      params,
    })
    .then(singularResponseHandler)
    .catch(generalErrorHandler)
}

const update = (resource, slug = '', data, params) => {
  return api
    .request({
      method: 'put',
      url: `${resource}/${slug}`,
      data,
      params,
    })
    .then(singularResponseHandler)
    .catch(generalErrorHandler)
}

const patch = (resource, slug = '', data, params) => {
  return api
    .request({
      method: 'patch',
      url: slug ? `${resource}/${slug}` : resource,
      data,
      params,
    })
    .then(singularResponseHandler)
    .catch(generalErrorHandler)
}

const remove = (resource, slug = '') => {
  return api
    .request({
      method: 'delete',
      url: `${resource}/${slug}`,
    })
    .then(singularResponseHandler)
    .catch(generalErrorHandler)
}

const upload = (resource, slug = '', file, type, params) => {
  return api
    .request({
      method: 'post',
      url: slug ? `${resource}/${slug}` : resource,
      data: file,
      headers: {
        'Content-Type': type,
      },
      params,
    })
    .then(singularResponseHandler)
    .catch(generalErrorHandler)
}

export default {
  setAuthorizationHeader,
  request,
  get,
  query,
  save,
  update,
  patch,
  remove,
  upload,
}
