import { toastController } from '@ionic/vue'

export default function useToast() {
  async function openToast({ color = 'dark', message, buttons = [] }) {
    const toast = await toastController.create({
      message,
      color,
      duration: 2000,
      buttons,
    })

    return toast.present()
  }

  return {
    openToast,
  }
}
