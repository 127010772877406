<template>
  <div class="cookie-container" v-if="isOpen && !hasAcceptedCookie">
    <div class="img-container">
      <ion-img :src="require('@/assets/images/icons/dog-biscuit.svg')" alt="Cookie" />
    </div>
    <div class="cookie-content">
      <div class="cookie-content cookie-content__header">
        <h2>Cookie</h2>
        <ion-img
          :src="require('@/assets/images/icons/close-black.svg')"
          alt="close"
          class="btn-close"
          @click.prevent="isOpen = false"
        />
      </div>
      <p>
        Jutalomfalatok nélkül a DOXOCIAL nem az igazi. A cookie-król az
        <a href="https://dox-tag.eu/privacy-policy" target="_blank">Adatkezelési elveinkben</a>
        olvashatsz.
      </p>
      <ion-button class="btn-primary" @click.prevent="setCookie">Elfogadom</ion-button>
    </div>
  </div>
</template>
<script>
import { defineComponent, computed, ref } from 'vue'
import { IonImg, IonButton } from '@ionic/vue'
import Cookies from 'js-cookie'

export default defineComponent({
  name: 'Cookie',
  components: {
    IonImg,
    IonButton,
  },
  setup() {
    const hasAcceptedCookie = computed(() => Cookies.get('DOXOCIAL'))
    const isOpen = ref(true)

    const setCookie = () => {
      const expiresDate = new Date()
      expiresDate.setFullYear(expiresDate.getFullYear() + 1)
      Cookies.set('DOXOCIAL', 1, { expires: expiresDate })
      isOpen.value = false
    }

    return {
      hasAcceptedCookie,
      setCookie,
      close,
      isOpen,
    }
  },
})
</script>
<style lang="scss" scoped>
ion-button {
  --background: #e6224a;
  --box-shadow: none;
}

.cookie-container {
  display: flex;
  align-items: flex-start;
  max-width: 490px;
  margin: 10px;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 1px 2px 10px 0 rgba(0, 0, 0, 0.15);
  position: fixed;
  bottom: 10px;
  left: calc((100vw - 1140px + 2rem) / 2);
  z-index: 999;
  background-color: #fff;

  @media (max-width: 991px) {
    display: none;
  }

  .img-container {
    margin-right: 15px;
  }

  h2 {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
  }

  .btn-close {
    max-width: 12px;
    cursor: pointer;
  }

  .cookie-content {
    &__header {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: space-between;
    }
  }
}
</style>
