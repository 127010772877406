import {
  PET_ROUTE,
  PET_PATH,
  PET_LOST_ROUTE,
  PET_LOST_PATH,
  PET_MESSAGE_ROUTE,
  PET_MESSAGE_PATH,
  PET_INFO_ROUTE,
  PET_INFO_PATH,
  PET_EDIT_ROUTE,
  PET_EDIT_PATH,
  PET_SEARCH_ROUTE,
  PET_SEARCH_PATH,
  PET_FINDER_ROUTE,
  PET_FINDER_PATH,
  SNIFF_SETTINGS_ROUTE,
  SNIFF_SETTINGS_PATH,
  DOX_DATA_ROUTE,
  DOX_DATA_PATH,
  DOX_READ_MAP_ROUTE,
  DOX_READ_MAP_PATH,
  PET_WALKING_ROUTE,
  PET_WALKING_PATH,
  PAGE_WALK_LEADERBOARD_ROUTE,
  PAGE_WALK_LEADERBOARD_PATH,
  PAGE_POI_RATE_ROUTE,
  PAGE_POI_RATE_PATH,
  PAGE_POI_CREATE_ROUTE,
  PAGE_POI_CREATE_PATH,
  PAGE_EVENT_CREATE_ROUTE,
  PAGE_EVENT_CREATE_PATH,
  PAGE_POI_EDIT_ROUTE,
  PAGE_POI_EDIT_PATH,
} from '@/constants/routes'

export default [
  {
    name: PET_ROUTE,
    path: PET_PATH,
    component: () => import(/* webpackChunkName: "pet" */ '@/views/Pets/Pet.vue'),
    meta: {
      canGoBack: true,
    },
  },
  {
    name: PET_LOST_ROUTE,
    path: PET_LOST_PATH,
    component: () => import(/* webpackChunkName: "pet" */ '@/views/Pets/PetLost.vue'),
    meta: {
      authenticatedOnly: true,
      canGoBack: true,
    },
  },
  {
    name: PET_MESSAGE_ROUTE,
    path: PET_MESSAGE_PATH,
    component: () => import(/* webpackChunkName: "pet" */ '@/views/Pets/MessagePet.vue'),
    meta: {
      authenticatedOnly: true,
      canGoBack: true,
    },
  },
  {
    name: PET_INFO_ROUTE,
    path: PET_INFO_PATH,
    component: () => import(/* webpackChunkName: "pet" */ '@/views/Pets/PetInfo.vue'),
    meta: {
      canGoBack: true,
    },
  },
  {
    name: PET_EDIT_ROUTE,
    path: PET_EDIT_PATH,
    component: () => import(/* webpackChunkName: "pet" */ '@/views/Pets/PetEdit.vue'),
    meta: {
      authenticatedOnly: true,
      canGoBack: true,
    },
  },
  {
    name: PET_SEARCH_ROUTE,
    path: PET_SEARCH_PATH,
    component: () => import(/* webpackChunkName: "pet" */ '@/views/Pets/PetSearch.vue'),
    meta: {
      authenticatedOnly: true,
    },
  },
  {
    name: DOX_DATA_ROUTE,
    path: DOX_DATA_PATH,
    component: () => import(/* webpackChunkName: "pet" */ '@/components/DoxData/DoxData.vue'),
    meta: {
      authenticatedOnly: true,
    },
  },
  {
    name: DOX_READ_MAP_ROUTE,
    path: DOX_READ_MAP_PATH,
    component: () => import(/* webpackChunkName: "pet" */ '@/components/DoxData/doxDataMap.vue'),
    meta: {
      canGoBack: true,
      authenticatedOnly: true,
      //   floatingButtonsDisabled: true,
    },
  },
  {
    name: PET_FINDER_ROUTE,
    path: PET_FINDER_PATH,
    component: () => import(/* webpackChunkName: "pet" */ '@/views/Pets/PetFinder.vue'),
    meta: {
      authenticatedOnly: true,
    },
  },
  {
    name: SNIFF_SETTINGS_ROUTE,
    path: SNIFF_SETTINGS_PATH,
    component: () => import(/* webpackChunkName: "pet" */ '@/views/Pets/PetFinderSettings.vue'),
    meta: {
      authenticatedOnly: true,
      canGoBack: true,
    },
  },
  {
    name: PET_WALKING_ROUTE,
    path: PET_WALKING_PATH,
    component: () => import(/* webpackChunkName: "pet" */ '@/views/Pets/PetWalking.vue'),
    meta: {
      authenticatedOnly: false,
      canGoBack: false,
    },
  },
  {
    name: PAGE_WALK_LEADERBOARD_ROUTE,
    path: PAGE_WALK_LEADERBOARD_PATH,
    component: () => import(/* webpackChunkName: "pet" */ '@/views/Pets/Leaderboard.vue'),
    meta: {
      canGoBack: true,
      authenticatedOnly: true,
    },
  },
  {
    name: PAGE_POI_RATE_ROUTE,
    path: PAGE_POI_RATE_PATH,
    component: () => import(/* webpackChunkName: "pet" */ '@/views/Pois/ReviewPoi.vue'),
    meta: {
      canGoBack: true,
      authenticatedOnly: true,
    },
  },
  {
    name: PAGE_POI_CREATE_ROUTE,
    path: PAGE_POI_CREATE_PATH,
    component: () => import(/* webpackChunkName: "pet" */ '@/views/Pois/CreatePoi.vue'),
    meta: {
      canGoBack: true,
      authenticatedOnly: true,
    },
  },
  {
    name: PAGE_EVENT_CREATE_ROUTE,
    path: PAGE_EVENT_CREATE_PATH,
    component: () => import(/* webpackChunkName: "pet" */ '@/views/Pois/CreateEvent.vue'),
    meta: {
      canGoBack: true,
      authenticatedOnly: true,
    },
  },
  {
    name: PAGE_POI_EDIT_ROUTE,
    path: PAGE_POI_EDIT_PATH,
    component: () => import(/* webpackChunkName: "pet" */ '@/views/Pois/EditPoi.vue'),
    meta: {
      canGoBack: true,
      authenticatedOnly: true,
    },
  },
]
